import { Pencil } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { RestaurantMenuType } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Link } from '@/components/shared/link';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<RestaurantMenuType>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'edit',
    header: '',
    cell: (cellData) => {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={`/restaurant/menu-types/edit/${cellData.row.original.id}`}
                className="flex items-center justify-center text-gray-700 "
              >
                <Pencil className="w-5 h-5" />
              </Link>
            </TooltipTrigger>
            <TooltipContent>{t('menu-type.edit-details')}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
];
