import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateCompanyLocationForm } from '@/components/company/create-company-location-form';

export const CreateCompanyLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => {
    navigate(`/company/locations`);
  };

  const isModalOpen = Boolean(useMatch({ path: '/company/locations/create' }));

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company-locations.create')}
      description={t('company-locations.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateCompanyLocationForm closeModal={closeModal} />
    </Modal>
  );
};
