import { PrinterIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import { cn } from '@/utils/helpers';

interface PrintButtonProps {
  className?: string;
  iconClassName?: string;
}

export const PrintButton = ({ className, iconClassName }: PrintButtonProps) => {
  const { t } = useTranslation();

  const handlePrint = () => {
    window.print();
  };

  return (
    <Button
      onClick={handlePrint}
      className={cn('print:hidden flex gap-1.5', className)}
    >
      <span>{t('common.print')}</span>
      <PrinterIcon className={cn('w-4 h-4', iconClassName)} />
    </Button>
  );
};
