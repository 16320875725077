import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@durma-soft/gros-sdk';

export const ProtectedRoute: FC<{ children?: ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();

  if (!user) {
    return <Navigate to="/auth/login" replace />;
  }

  return <>{children}</>;
};
