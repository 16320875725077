import { Link } from '@/components/shared/link';
import { useTranslation } from 'react-i18next';

export const SidebarHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-14 items-center border-b px-4 lg:h-15 lg:px-6">
      <Link to="/" className="flex items-center gap-2 font-semibold">
        <span>{t('common.title')}</span>
      </Link>
    </div>
  );
};
