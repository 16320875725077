import { TType } from '@/types/general';
import { LocationDeliveryType } from '@/types/companies';
import { EmployeeSexTypes } from '@/types/employees';

export const locationDeliveryTypes = (t: TType): LocationDeliveryType[] => [
  {
    value: 'direct',
    label: t('location-type.delivery'),
  },
  {
    value: 'track',
    label: t('location-type.track'),
  },
];

export const employeeSex = (t: TType): EmployeeSexTypes[] => [
  {
    value: 'male',
    label: t('common.male'),
  },
  {
    value: 'female',
    label: t('common.female'),
  },
];
