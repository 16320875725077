import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/shared/modal';
import { Button } from '@/components/shared/shadcn-ui/button';

interface ConfirmationModalProps {
  isLoading?: boolean;
  isModalOpen: boolean;
  title: string;
  description?: string;
  confirmationText?: string;
  closeModal?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationModal = ({
  isLoading,
  isModalOpen,
  title,
  description,
  confirmationText,
  onCancel,
  onConfirm,
  closeModal,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      description={description}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    >
      <div className="mt-4 flex justify-end gap-2.5">
        <Button onClick={onCancel} variant="outline">
          {t('common.cancel')}
        </Button>
        <Button onClick={onConfirm} variant="destructive" isLoading={isLoading}>
          {confirmationText ? confirmationText : t('common.confirm')}
        </Button>
      </div>
    </Modal>
  );
};
