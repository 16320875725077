import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Locale, updateLanguage } from '@durma-soft/gros-sdk';

import { SelectLanguage } from '@/components/shared/select-language';

const Auth = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('common.authentication')}</title>
      </Helmet>

      <div className="grid w-full h-dvh lg:grid-cols-2">
        <div className="h-full w-full flex flex-col">
          <div className="self-end p-4">
            <SelectLanguage
              value={i18n.language as Locale}
              onValueChange={(lang) => {
                updateLanguage(lang as Locale);
                i18n.changeLanguage(lang);
              }}
              placeholder={t(`languages.${i18n.language as Locale}`)}
            />
          </div>
          <div className="flex-1 flex items-center justify-center">
            <Outlet />
          </div>
        </div>
        {/* Simulating image */}
        <div className="hidden w-full h-full bg-orange-100 bg-muted lg:block"></div>
      </div>
    </>
  );
};

export default Auth;
