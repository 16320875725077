import { toast } from 'sonner';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCameraBarcodeScanner = () => {
  const { t } = useTranslation();
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openScanner = async () => {
    try {
      setIsLoading(true);
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { min: 1280 },
          height: { min: 720 },
        },
      });
      setIsOpen(true);
    } catch (error) {
      setIsOpen(false);
      toast.error(t('track.camera-access-not-allowed'));
    } finally {
      setIsLoading(false);
    }
  };

  const closeScanner = () => {
    mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    mediaStreamRef.current = null;
    setIsOpen(false);
    setIsLoading(false);
  };

  return {
    isOpen,
    isLoading,
    openScanner,
    closeScanner,
  };
};
