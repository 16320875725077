import { Navigate, Outlet } from 'react-router-dom';
import { COMPANY_USER_ROLE, useAuthUserCompany } from '@durma-soft/gros-sdk';

export const UserCanAccessTrackMiddleware = () => {
  const { role } = useAuthUserCompany();

  if (role !== COMPANY_USER_ROLE.TRACK_EMPLOYEE) {
    return (
      <>
        <Navigate to="/" />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};
