import { ChangeEvent } from 'react';
import { Search } from 'lucide-react';

import { Input } from '@/components/shared/shadcn-ui/input';

import { cn } from '@/utils/helpers';

interface SearchInputProps {
  className?: string;
  placeholder?: string;
  searchInput?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({
  className,
  placeholder,
  searchInput,
  handleChange,
}: SearchInputProps) => {
  return (
    <div
      className={cn(
        'bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60',
        className,
      )}
    >
      <form>
        <div className="relative">
          <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground z-10" />
          <Input
            placeholder={placeholder}
            className="pl-8"
            value={searchInput || ''}
            onChange={handleChange}
          />
        </div>
      </form>
    </div>
  );
};
