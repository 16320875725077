import { toast } from 'sonner';
import { CircleUserRound } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from '@/components/shared/shadcn-ui/dropdown-menu';

export const UserAccount = () => {
  const { t } = useTranslation();
  const logout = useLogoutMutation();

  const handleLogout = () => {
    logout.mutateAsync();
    toast.success(t('auth.logout-success'));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="icon"
          className="rounded-full flex justify-center items-center"
        >
          <CircleUserRound className="w-5 h-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.account')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout} className="cursor-pointer">
          {t('auth.logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
