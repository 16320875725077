import { useTranslation } from 'react-i18next';
import { useAuthUserRestaurant } from '@durma-soft/gros-sdk';
import { ShortRestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Label } from '@/components/shared/shadcn-ui/label';
import { FoodsTable } from '@/components/menus//foods-table';
import { FoodsCombobox } from '@/components/menus/food-combobox';

interface FoodsTabProps {
  foods: ShortRestaurantFood[];
  onUpdateFoods: (newFood: ShortRestaurantFood[]) => unknown;
  onDeleteFood: (id: number) => unknown;
}

export const FoodsTab = ({
  foods,
  onUpdateFoods,
  onDeleteFood,
}: FoodsTabProps) => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  return (
    <div className="flex flex-col gap-4">
      <Label>{t('menu.choose-foods')}</Label>
      <FoodsCombobox
        restaurantId={restaurant.id}
        selectedFoods={foods ?? []}
        onUpdateFoods={onUpdateFoods}
      />

      <FoodsTable
        foods={foods}
        onUpdateFoods={onUpdateFoods}
        onDeleteFood={onDeleteFood}
      />
    </div>
  );
};
