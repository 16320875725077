import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantEmployeeForm } from '@/components/employees/create-restaurant-employee-form';
import { useTranslation } from 'react-i18next';

export const CreateRestaurantEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate('/restaurant/employees');

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/employees/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('employee.create')}
      description={t('employee.create-details')}
      className="flex flex-col px-6 overflow-y-hidden"
    >
      <CreateRestaurantEmployeeForm closeModal={closeModal} />
    </Modal>
  );
};
