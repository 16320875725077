import { twMerge } from 'tailwind-merge';
import { type ClassValue, clsx } from 'clsx';

import { Time } from '@/types/general';

export function nextTick(func: () => unknown) {
  setTimeout(func, 0);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function repeat(times: number) {
  return Array.from(Array(times).keys());
}

export function noop() {
  // Do nothing
}

export function formatTime(time: Time) {
  const { hour, minute } = time;
  return `${hour}:${minute}:00`;
}

export function isNotEmpty(str: string) {
  return str.trim().length > 0;
}
