import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useId } from 'react';
import { RestaurantMenuType } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Alert } from '@/components/shared/alert';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { Checkbox } from '@/components/shared/shadcn-ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { shifts as localizedShifts } from '@/config/restaurant/menus';
import { RestaurantMenuFormData } from '@/types/menus';

interface MenuDetailsProps {
  menuTypes: RestaurantMenuType[];
  formData: RestaurantMenuFormData;
  setFormData: Dispatch<SetStateAction<RestaurantMenuFormData>>;
}

export const CreateMenuDetails = ({
  menuTypes,
  formData,
  setFormData,
}: MenuDetailsProps) => {
  const { t } = useTranslation();
  const uid = useId();

  const handleShiftsChange = (id: number, isChecked: boolean) => {
    setFormData((prevData) => {
      if (!('shifts' in prevData)) return prevData;
      const shifts = prevData.shifts ?? [];
      return {
        ...prevData,
        shifts: isChecked
          ? [...shifts, id]
          : shifts.filter((shiftId) => shiftId !== id),
      };
    });
  };

  return (
    <div className="grid gap-4">
      {/* Menu type */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-menu-type'}>{t('menu.menu-type')}</Label>
        {menuTypes?.length === 0 ? (
          <Alert
            title={t('menu-type.not-entered')}
            description={t('menu-type.not-entered-description')}
            variant="destructive"
          />
        ) : (
          <Select
            value={
              menuTypes?.find(
                (menuType) => formData.menu_type_id === menuType.id,
              )?.name as string
            }
            onValueChange={(e) =>
              setFormData({
                ...formData,
                menu_type_id: menuTypes?.find(
                  (menuType) => menuType.name === (e as string),
                )?.id as number,
              })
            }
          >
            <SelectTrigger id={uid + '-menu-type'}>
              <SelectValue placeholder={t('menu.choose-menu-type')} />
            </SelectTrigger>
            <SelectContent>
              {menuTypes &&
                menuTypes?.map(({ id, name }) => (
                  <SelectItem key={id} value={name}>
                    {name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        )}
      </div>

      {/* Shift/s */}
      <div className="flex flex-col [&:not(:last-child)]:gap-2">
        {localizedShifts(t)?.map(({ id, label }) => (
          <div className="flex items-center gap-3" key={id}>
            <Checkbox
              id={label}
              checked={formData.shifts?.includes(id) || false}
              onCheckedChange={(e) => handleShiftsChange(id, Boolean(e))}
            />
            <label
              htmlFor={label}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {label}
            </label>
          </div>
        ))}
      </div>
      {/* Active */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + 'is-active'}>{t('menu.is-active')}</Label>
        <Switch
          id={uid + 'is-active'}
          onCheckedChange={(value) => {
            setFormData((prevData) => {
              return { ...prevData, is_active: value };
            });
          }}
          checked={formData.is_active}
        />
      </div>
    </div>
  );
};
