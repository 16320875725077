import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '@durma-soft/gros-sdk';
import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

type Mode = 'restaurant' | 'company' | 'unknown';

interface AppModeContextType {
  availableModes: Mode[];
  currentMode: Mode;
  setMode: (mode: Mode) => void;
}

const AppModeContext = createContext<AppModeContextType>({
  availableModes: [],
  currentMode: 'unknown',
  setMode: () => {},
});

const AppModeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const availableModes: Mode[] = useMemo(() => {
    const modes: Mode[] = [];
    if (user?.restaurant) modes.push('restaurant');
    if (user?.company) modes.push('company');
    return modes;
  }, [user]);

  const mode: Mode = useMemo(() => {
    if (pathname.startsWith('/company')) return 'company';
    if (pathname.startsWith('/restaurant')) return 'restaurant';
    return 'unknown';
  }, [pathname]);

  const setMode = (newMode: Mode) => {
    if (newMode === mode) return;
    if (newMode === 'restaurant') navigate('/restaurant');
    if (newMode === 'company') navigate('/company');
    navigate('/');
  };

  return (
    <AppModeContext.Provider
      value={{ availableModes, currentMode: mode, setMode }}
    >
      {children}
    </AppModeContext.Provider>
  );
};

export const useAppMode = () => useContext(AppModeContext);

export default AppModeProvider;
