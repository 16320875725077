import { Locale, enUS, srLatn, sl, bs, hr } from 'date-fns/locale';
import { Locale as LocaleType } from '@durma-soft/gros-sdk';

import { TType } from '@/types/general';

type ExtendedLocaleType = LocaleType;

export const locales: Record<ExtendedLocaleType, Locale> = {
  rs: srLatn,
  en: enUS,
  bs: bs,
  si: sl,
  hr: hr,
};

export const languages = (t: TType) => [
  { value: 'rs', name: t('languages.sr') },
  { value: 'en', name: t('languages.en') },
  {
    value: 'bs',
    name: t('languages.bs'),
  },
  { value: 'hr', name: t('languages.hr') },

  { value: 'si', name: t('languages.si') },
];
