import { ColumnDef } from '@tanstack/react-table';
import { RestaurantsReportRow } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<RestaurantsReportRow>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'orders_count',
    header: t('reports.restaurants.orders-count'),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => {
        const rowValue = Number(row.getValue('orders_count'));
        return total + rowValue;
      }, 0);
    },
  },
];
