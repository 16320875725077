import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantFoodForm } from '@/components/foods/create-restaurant-food-form';

export const CreateRestaurantFoodModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate('/restaurant/foods');

  const isModalOpen = Boolean(useMatch({ path: '/restaurant/foods/create' }));

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('food.create')}
      description={t('food.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateRestaurantFoodForm closeModal={closeModal} />
    </Modal>
  );
};
