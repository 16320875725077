import { ReactNode } from 'react';
import { cn } from '@/utils/helpers';

interface InputAddonProps {
  children?: ReactNode;
  position?: 'left' | 'right';
  onClick?: () => unknown;
}

export const InputAddon = ({
  children,
  position = 'right',
  onClick,
}: InputAddonProps) => (
  <div
    role={onClick ? 'button' : undefined}
    onClick={onClick}
    className={cn(
      'flex items-center justify-center px-3 border border-input',
      position === 'left' ? 'rounded-l-md' : 'rounded-r-md',
    )}
  >
    <span className="text-sm text-gray-700 select-none">{children}</span>
  </div>
);
