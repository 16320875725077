import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useForgotPasswordMutation,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';

import { isNotEmpty } from '@/utils/helpers';

const initialFormData = {
  username: '',
};

const ForgotPassword = () => {
  const id = useId();
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const forgotPasswordMutation = useForgotPasswordMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(formData.username);
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      forgotPasswordMutation.mutateAsync(formData);
      toast.success(t('auth.reset-password-link-sent'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('auth.send-email-error'));
    }
  };

  if (forgotPasswordMutation.isSuccess) {
    return (
      <Alert
        title={t('common.success')}
        description={t('auth.reset-password-link-sent')}
      />
    );
  }

  if (forgotPasswordMutation.isError) {
    return (
      <Alert
        title={t('common.error')}
        description={t('auth.send-email-error')}
        variant="destructive"
      />
    );
  }

  return (
    <div className="flex items-center justify-center py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t('auth.reset-password')}</h1>
          <p className="text-balance text-muted-foreground">
            {t('auth.to-send-reset-link')}
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor={id + '-username'}>{t('common.username')}</Label>
              <Input
                id={id + '-username'}
                required
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <Button
              type="submit"
              disabled={!isValid || forgotPasswordMutation.isPending}
              isLoading={forgotPasswordMutation.isPending}
              className="w-full"
            >
              {t('common.send')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
