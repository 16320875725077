import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleRestaurantIngredientQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantIngredientForm } from '@/components/ingredients/edit-restaurant-ingredient-form';

export const EditRestaurantIngredientModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ingredientId } = useParams();

  if (!ingredientId) {
    throw new Error('Ingredient ID is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: ingredient,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleRestaurantIngredientQuery({
    restaurant_id: restaurant.id,
    ingredient_id: Number(ingredientId),
  });

  const closeModal = () => navigate('/restaurant/ingredients');

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/ingredients/edit/:ingredientId' }),
  );

  return (
    <Modal
      key={ingredientId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('ingredient.edit')}
      description={t('ingredient.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantIngredientForm
          ingredient={ingredient}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
