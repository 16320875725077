import { toast } from 'sonner';
import { CircleAlert } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useResetPasswordMutation,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { Alert } from '@/components/shared/alert';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button, buttonVariants } from '@/components/shared/shadcn-ui/button';

const RESET_PASSWORD_SEARCH_PARAMS = {
  EMAIL: 'email',
  TOKEN: 'token',
  USERNAME: 'username',
};

const initialFormData = {
  password: '',
  password_confirmation: '',
};

const ResetPassword = () => {
  const uid = useId();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(initialFormData);
  const resetPasswordMutation = useResetPasswordMutation();

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!doesPasswordsMatch) return;

    try {
      await resetPasswordMutation.mutateAsync({
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        email: searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.EMAIL) || '',
        token: searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.TOKEN) || '',
        username: searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.USERNAME) || '',
      });
      toast.success(t('auth.reset-password-success'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('auth.reset-password-error'));
    }
  };

  if (
    !searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.EMAIL) ||
    !searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.TOKEN) ||
    !searchParams.get(RESET_PASSWORD_SEARCH_PARAMS.USERNAME)
  ) {
    return (
      <Alert
        title={t('common.error')}
        description={t('auth.reset-password-error')}
        variant="destructive"
      />
    );
  }

  if (resetPasswordMutation.isSuccess) {
    return (
      <div className="flex flex-col items-center justify-center gap-3">
        <Alert
          title={t('common.success')}
          description={t('auth.reset-password-success')}
        />
        <Link
          to="/auth/login"
          className={buttonVariants({ variant: 'default' })}
        >
          {t('auth.login')}
        </Link>
      </div>
    );
  }

  if (resetPasswordMutation.isError) {
    return (
      <Alert
        title={t('common.error')}
        description={t('auth.reset-password-expired-link')}
        variant="destructive"
      />
    );
  }

  return (
    <div className="flex items-center justify-center py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t('auth.new-password')}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor={uid + '-password'}>
                {t('auth.new-password')}
              </Label>
              <Input
                required
                id={uid + '-password'}
                type="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                className={`${
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  'ring-2 ring-offset-2 ring-red-500 focus-visible:ring-red-500'
                }`}
              />
            </div>
            <div>
              <div className="grid gap-2">
                <Label htmlFor={uid + '-password-confirmation'}>
                  {t('common.password-confirmation')}
                </Label>
                <Input
                  required
                  id={uid + '-password-confirmation'}
                  type="password"
                  value={formData.password_confirmation}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      password_confirmation: e.target.value,
                    })
                  }
                  className={`${
                    formData.password_confirmation !== '' &&
                    !doesPasswordsMatch &&
                    'ring-2 ring-offset-2 ring-red-500 focus-visible:ring-red-500'
                  }`}
                />
              </div>
              {/* Passwords not matching */}
              {formData.password_confirmation !== '' && !doesPasswordsMatch && (
                <Label className="text-red-500 flex gap-2 items-center">
                  <CircleAlert />
                  <span>{t('common.passwords-must-match')}</span>
                </Label>
              )}
            </div>
            <Button
              type="submit"
              className="w-full"
              isLoading={resetPasswordMutation.isPending}
              disabled={!doesPasswordsMatch || resetPasswordMutation.isPending}
            >
              {t('common.save')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
