import { ReactNode } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

interface DataTableDnDContextProps<TData = unknown> {
  children?: ReactNode;
  data: TData[];
  setData: (data: TData[]) => void;
  dataIds: Array<UniqueIdentifier>;
}

export const DataTableDnDContext = ({
  children,
  data,
  setData,
  dataIds,
}: DataTableDnDContextProps) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over) {
      const activeId = active.id as UniqueIdentifier;
      const overId = over.id as UniqueIdentifier;

      if (activeId !== overId) {
        const oldIndex = dataIds.indexOf(activeId);
        const newIndex = dataIds.indexOf(overId);

        if (oldIndex !== -1 && newIndex !== -1) {
          const newData = arrayMove(data, oldIndex, newIndex);
          setData(newData);
        }
      }
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
      )}
    >
      {children}
    </DndContext>
  );
};
