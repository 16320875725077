import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/shared/alert';

const NotAllowed = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('common.not-allowed')}</title>
      </Helmet>
      <Alert
        variant="destructive"
        title={t('common.not-allowed')}
        description={t('common.not-allowed-description')}
        className="mx-auto"
      />
    </>
  );
};

export default NotAllowed;
