import { toast } from 'sonner';
import { SearchX } from 'lucide-react';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserCompany,
  useCreateTrackSubstituteOrderMutation,
  useGetTrackSubstituteMealsQuery,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import {
  Card,
  CardDescription,
  CardTitle,
} from '@/components/shared/shadcn-ui/card';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';

import { noop, repeat } from '@/utils/helpers';

interface TrackNoOrderProps {
  userId: number;
  shift: RestaurantShift;
  onOrderCreated?: () => void;
}

export const TrackNoOrder = ({
  userId,
  shift,
  onOrderCreated = noop,
}: TrackNoOrderProps) => {
  const radioId = useId();
  const { t } = useTranslation();
  const { id: company_id } = useAuthUserCompany();
  const [selectedFoodId, setSelectedFoodId] = useState<string | null>(null);

  const { data, isSuccess, isLoading, isError, refetch, isRefetching } =
    useGetTrackSubstituteMealsQuery({
      company_id,
      shift,
    });

  const createSubstituteOrderMutation = useCreateTrackSubstituteOrderMutation();

  const handleCreateSubstituteOrder = async () => {
    if (createSubstituteOrderMutation.isPending || !selectedFoodId) return;
    try {
      await createSubstituteOrderMutation.mutateAsync({
        company_id,
        user_id: userId,
        food_id: +selectedFoodId,
        shift,
      });
      toast.success(t('track.no-order.order-created-success-msg'));
      onOrderCreated();
    } catch (error) {
      toast.error(t('track.no-order.order-created-error-msg'));
    }
  };

  return (
    <Card className="flex flex-col gap-4 p-5">
      <SearchX className="h-16 w-16 text-black" />
      <CardTitle>{t('track.no-order.title')}</CardTitle>
      {isSuccess && data.length !== 0 && (
        <CardDescription>{t('track.no-order.substitute-meal')}</CardDescription>
      )}
      {isLoading && (
        <div className="flex flex-col gap-3">
          {repeat(3).map((_, i) => (
            <Skeleton key={i} className="w-full h-4" />
          ))}
          <Skeleton className="w-1/3 h-8 ml-auto" />
        </div>
      )}
      {isError && (
        <>
          <Alert
            variant="destructive"
            title={t('track.no-order.substitute-meal-error-msg')}
            description={t('track.no-order.substitute-meal-error-msg-extended')}
          />
          <Button isLoading={isRefetching} onClick={() => refetch()}>
            {t('common.try-later')}
          </Button>
        </>
      )}
      {isSuccess && data.length === 0 && (
        <CardDescription>
          {t('track.no-order.no-substitute-meals')}
        </CardDescription>
      )}
      {isSuccess && data.length > 0 && (
        <div className="mt-2 space-y-5">
          <RadioGroup
            id={radioId}
            value={selectedFoodId || ''}
            onValueChange={(value) => setSelectedFoodId(value)}
          >
            {data.map(({ id, name }) => (
              <div key={id} className="flex items-center space-x-2">
                <RadioGroupItem value={`${id}`} id={`${radioId}-${id}`} />
                <Label htmlFor={`${radioId}-${id}`}>{name}</Label>
              </div>
            ))}
          </RadioGroup>
          <Button
            onClick={handleCreateSubstituteOrder}
            isLoading={createSubstituteOrderMutation.isPending}
            disabled={!selectedFoodId}
          >
            {t('track.no-order.create-order')}
          </Button>
        </div>
      )}
    </Card>
  );
};
