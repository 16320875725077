import { repeat } from '@/utils/helpers';
import { Skeleton } from './shadcn-ui/skeleton';

interface ModalFormSkeletonProps {
  numberOfSkeletons?: number;
}

export const ModalFormSkeleton = ({
  numberOfSkeletons = 5,
}: ModalFormSkeletonProps) => {
  return (
    <>
      {repeat(numberOfSkeletons).map((_, index) => (
        <div className="flex flex-col gap-2" key={index}>
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-6 w-full" />
        </div>
      ))}
      <div className="flex justify-end">
        <Skeleton className="h-10 w-1/3" />
      </div>
    </>
  );
};
