import { Locale } from '@durma-soft/gros-sdk';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/shared/spinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { languages } from '@/config/global/languages';

interface SelectLanguageProps {
  value: Locale;
  placeholder: string;
  isLoading?: boolean;
  onValueChange: (value: string) => void;
}

export const SelectLanguage = ({
  value,
  placeholder = '',
  isLoading = false,
  onValueChange,
}: SelectLanguageProps) => {
  const { t } = useTranslation();
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <SelectValue placeholder={placeholder} />
        )}
      </SelectTrigger>

      <SelectContent>
        {languages(t).map(({ name, value }) => (
          <SelectItem key={value} value={value}>
            {name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
