import { Outlet } from 'react-router-dom';
import { UserCanAccessRestaurantMiddleware } from '@/middleware/user-can-access-restaurant-middleware';

const Restaurant = () => {
  return (
    <UserCanAccessRestaurantMiddleware>
      <Outlet />
    </UserCanAccessRestaurantMiddleware>
  );
};

export default Restaurant;
