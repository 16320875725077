import { useTranslation } from 'react-i18next';
import { useAuthUser } from '@durma-soft/gros-sdk';

import { noop } from '@/utils/helpers';
import { config } from '@/config/global/sidebar';
import { useAppMode } from '@/providers/AppModeProvider';
import { SidebarNavItem } from '@/layout/sidebar/sidebar-nav-item';

interface SidebarNavProps {
  onCloseSidemenu?: () => void;
}

export const SidebarNav = ({ onCloseSidemenu = noop }: SidebarNavProps) => {
  const { t } = useTranslation();
  const { availableModes } = useAppMode();

  const user = useAuthUser();

  const getSidebarItems = () => {
    if (availableModes.includes('restaurant')) {
      const items = config(t).restaurantItems;
      const userRole = user.restaurant?.role;
      if (!userRole) return items;
      return items.filter((item) => !item.hideFrom?.includes(userRole));
    }

    if (availableModes.includes('company')) {
      const items = config(t).companyItems;
      const userRole = user.company?.role;
      if (!userRole) return items;
      return items.filter((item) => !item.hideFrom?.includes(userRole));
    }

    return [];
  };

  const sidebarItems = getSidebarItems();

  if (sidebarItems.length === 0) return null;

  return (
    <nav className="flex-1 overflow-y-auto custom-scrollbar">
      <ul className="flex flex-col h-full px-2 text-sm font-medium lg:px-4">
        {sidebarItems.map((item, index) => (
          <SidebarNavItem
            key={index}
            item={item}
            onCloseSidemenu={onCloseSidemenu}
          />
        ))}
      </ul>
    </nav>
  );
};
