import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from '@durma-soft/gros-sdk';

interface UserHasCompanyOrRestaurantMiddlewareProps {
  children?: ReactNode;
}

export const UserHasCompanyOrRestaurantMiddleware = ({
  children,
}: UserHasCompanyOrRestaurantMiddlewareProps) => {
  const { t } = useTranslation();
  const { restaurant, company } = useAuthUser();

  if (!restaurant && !company) {
    // TODO: RENDER A GENERIC ERROR MESSAGE
    return <div>{t('common.error')}</div>;
  }

  return <>{children}</>;
};
