import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleMenuQuery,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantMenuForm } from '@/components/menus/edit-restaurant-menu-form';

interface EditRestaurantMenuModalProps {
  date: string;
}

export const EditRestaurantMenuModal = ({
  date,
}: EditRestaurantMenuModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { menuId } = useParams();

  if (!menuId) {
    throw new Error('FoodId ID is required for editing employee');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: menu,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleMenuQuery({
    restaurant_id: restaurant.id,
    menu_id: Number(menuId),
  });

  const closeModal = () => navigate(`/restaurant/menus?date=${date}`);

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/menus/edit/:menuId' }),
  );

  return (
    <Modal
      key={menuId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('menu.edit')}
      description={t('menu.edit-details')}
      className="flex flex-col overflow-y-hidden"
    >
      {isLoading && <ModalFormSkeleton numberOfSkeletons={10} />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantMenuForm menu={menu} closeModal={closeModal} />
      )}
    </Modal>
  );
};
