import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Link } from '@/components/shared/link';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('common.not-found')}</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center gap-2">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('common.not-found')}
        </h1>
        <Link className={buttonVariants()} to="/restaurant/menus">
          {t('common.back-to-homepage')}
        </Link>
      </div>
    </>
  );
};

export default NotFound;
