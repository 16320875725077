import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  isAxiosError,
  TRACK_ERROR,
  TrackError,
  useAuthUserCompany,
  useTrackScanQRCodeMutation,
} from '@durma-soft/gros-sdk';

import { useBarcodeScanner } from '@/hooks/use-barcode-scanner';
import { useCameraBarcodeScanner } from '@/hooks/use-camera-barcode-scanner';

import { Scanner } from '@yudiel/react-qr-scanner';
import { Spinner } from '@/components/shared/spinner';
import { Card } from '@/components/shared/shadcn-ui/card';
import { Button } from '@/components/shared/shadcn-ui/button';
import { SelectShift } from '@/components/shared/select-shift';
import { TrackNoOrder } from '@/components/track/track-no-order';
import { TrackIdleCard } from '@/components/track/track-idle-card';
import { PickedUpOrders } from '@/components/track/picked-up-orders';
import { GenericTrackError } from '@/components/track/generic-track-error';
import { UnknownTrackError } from '@/components/track/unknown-track-error';
import { FocusWindowOverlay } from '@/components/track/focus-window-overlay';

const CompanyTrack = () => {
  const { t } = useTranslation();
  const { id: company_id } = useAuthUserCompany();
  const [selectedShift, setSelectedShift] = useState<RestaurantShift>('1');

  const {
    isOpen: QRScannerOpen,
    isLoading: QRScannerLoading,
    openScanner,
    closeScanner,
  } = useCameraBarcodeScanner();

  const { isIdle, isPending, isSuccess, isError, error, mutate, data, reset } =
    useTrackScanQRCodeMutation();

  const onScan = useCallback(
    (code: string) => {
      mutate({
        company_id,
        qr_code: code,
        shift: selectedShift,
      });
    },
    [mutate, selectedShift, company_id],
  );

  useBarcodeScanner({ onScan });

  const trackError: TrackError | null = useMemo(() => {
    if (!isError) return null;
    if (isAxiosError(error)) {
      const possibleTrackErrors = Object.values(TRACK_ERROR);
      if (
        error?.response?.data?.type &&
        possibleTrackErrors.includes(error.response.data.type)
      ) {
        return error.response.data as TrackError;
      }
      return null;
    }
    return null;
  }, [isError, error]);

  return (
    <>
      <Helmet>
        <title>{t('track.title')}</title>
      </Helmet>

      <FocusWindowOverlay enabled={false} />
      <PickedUpOrders shift={selectedShift} />

      <div className="flex flex-col gap-5 pr-80">
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('track.title')}
          </h1>
        </div>
        <div className="flex items-center gap-3">
          {QRScannerOpen ? (
            <Button onClick={closeScanner} disabled={QRScannerLoading}>
              {t('track.hide-qr-scanner')}
            </Button>
          ) : (
            <Button isLoading={QRScannerLoading} onClick={openScanner}>
              {t('track.show-qr-scanner')}
            </Button>
          )}

          <SelectShift
            className="inline-flex w-auto min-w-[120px]"
            shift={selectedShift}
            setShift={(shift) => {
              reset();
              setSelectedShift(shift);
            }}
          />
        </div>
        {QRScannerOpen && (
          <div className="w-80 h-80 mx-auto">
            <Scanner
              allowMultiple
              key={`${selectedShift}-${company_id}`}
              onScan={(result) => {
                const firstResult = result[0];
                if (!firstResult) return;
                const code = firstResult.rawValue;
                mutate({
                  company_id,
                  qr_code: code,
                  shift: selectedShift,
                });
              }}
              onError={(err) => console.log(err)}
            />
          </div>
        )}

        <div className="mt-6">
          {isIdle && <TrackIdleCard />}
          {isPending && (
            <Card className="p-5">
              <Spinner className="mx-auto" />
            </Card>
          )}
          {isSuccess && (
            <Card className="p-5">
              <p className="font-semibold text-2xl mb-4">
                {data.user.first_name} {data.user.last_name}
              </p>
              <ul className="space-y-2.5">
                {data.foods.map((food) => (
                  <li key={food.id}>
                    <span className="font-medium">{food.quantity}x</span>{' '}
                    {food.name}
                  </li>
                ))}
              </ul>
            </Card>
          )}
          {isError && !trackError && <UnknownTrackError />}
          {isError && trackError && (
            <>
              {trackError.type === TRACK_ERROR.INVALID_QR_CODE && (
                <GenericTrackError
                  title={t('track.errors.invalid-qr-code.heading')}
                  description={t('track.errors.invalid-qr-code.description')}
                  onClose={reset}
                />
              )}
              {trackError.type === TRACK_ERROR.ORDER_ALREADY_PICKED_UP && (
                <GenericTrackError
                  title={t('track.errors.order-already-picked-up.heading')}
                  description={t(
                    'track.errors.order-already-picked-up.description',
                  )}
                  onClose={reset}
                />
              )}
              {trackError.type === TRACK_ERROR.WRONG_LOCATION && (
                <GenericTrackError
                  title={t('track.errors.wrong-location.heading')}
                  description={t('track.errors.wrong-location.description')}
                  onClose={reset}
                />
              )}
              {trackError.type === TRACK_ERROR.NO_ORDER && (
                <TrackNoOrder
                  userId={trackError.user_id}
                  shift={selectedShift}
                  onOrderCreated={reset}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyTrack;
