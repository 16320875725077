import clsx from 'clsx';
import { ReactNode } from 'react';
import { AlertCircle } from 'lucide-react';

import {
  AlertDescription,
  AlertTitle,
  Alert as ShadcnAlert,
} from '@/components/shared/shadcn-ui/alert';

type AlertVariant = 'default' | 'destructive' | null | undefined;

interface AlertProps {
  icon?: ReactNode;
  title: string;
  description: string;
  variant?: AlertVariant;
  className?: string;
}

export const Alert = ({
  icon,
  title,
  description,
  variant = 'default',
  className,
}: AlertProps) => {
  return (
    <ShadcnAlert variant={variant} className={clsx('max-w-md', className)}>
      {variant === 'destructive' ? (
        <AlertCircle className="w-4 h-4" />
      ) : icon ? (
        <div className="w-4 h-4">{icon}</div>
      ) : null}
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </ShadcnAlert>
  );
};
