import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { DataTable } from '@/components/shared/data-table';

import { columns } from '@/config/restaurant/food-ingredients';
import {
  RestaurantFoodFormData,
  ShortRestaurantIngredientWithQuantity,
} from '@/types/foods';

interface IngredientsTableProps {
  ingredients: ShortRestaurantIngredientWithQuantity[];
  setFormData: Dispatch<SetStateAction<RestaurantFoodFormData>>;
}

export const IngredientsTable = ({
  ingredients,
  setFormData,
}: IngredientsTableProps) => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const onUpdateCellValue = (
    rowIndex: number,
    columnId: string,
    value: string,
  ) => {
    const updatedData = ingredients?.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });
    setFormData((prevData) => ({
      ...prevData,
      ingredients: updatedData,
    }));
  };

  return (
    <DataTable
      getRowId={(ingredient) => `${ingredient.id}`}
      columns={memoizedColumns}
      data={ingredients}
      onUpdateCellValue={onUpdateCellValue}
    />
  );
};
