import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { COMPANY_USER_ROLE, useAuthUser } from '@durma-soft/gros-sdk';

interface UserCanAccessCompanyMiddlewareProps {
  children?: ReactNode;
}

export const UserCanAccessCompanyMiddleware = ({
  children,
}: UserCanAccessCompanyMiddlewareProps) => {
  const { company } = useAuthUser();

  if (!company) {
    return <Navigate to="/" />;
  }

  if (company.role === COMPANY_USER_ROLE.EMPLOYEE) {
    return <Navigate to="/not-allowed" />;
  }

  return <>{children}</>;
};
