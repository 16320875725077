import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useWindowFocus } from '@/hooks/use-window-focus';

interface FocusWindowOverlayProps {
  enabled?: boolean;
}

export const FocusWindowOverlay = ({
  enabled = true,
}: FocusWindowOverlayProps) => {
  const { t } = useTranslation();
  const isFocused = useWindowFocus();
  if (isFocused || !enabled) return null;
  return (
    <>
      {createPortal(
        <div className="fixed inset-0 z-50 bg-black/70 flex items-center justify-center cursor-pointer">
          <div className="container text-white">
            <p className="text-3xl text-center max-w-[700px] mx-auto w-full select-none">
              {t('track.focus-window-overlay')}
            </p>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};
