import { ReactNode } from 'react';
import { OctagonAlert, X } from 'lucide-react';

import {
  Card,
  CardDescription,
  CardTitle,
} from '@/components/shared/shadcn-ui/card';

interface GenericTrackErrorProps {
  title?: ReactNode;
  description?: ReactNode;
  onClose?: () => void;
}

export const GenericTrackError = ({
  title,
  description,
  onClose,
}: GenericTrackErrorProps) => {
  return (
    <Card className="p-5 flex flex-col gap-4 relative">
      <OctagonAlert className="h-16 w-16 text-black" />
      {onClose && (
        <button
          type="button"
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X className="h-4 w-4" />
        </button>
      )}

      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </Card>
  );
};
