import { useState } from 'react';
import { MenuIcon } from 'lucide-react';

import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from '@/components/shared/shadcn-ui/sheet';

import { SidebarNav } from '@/layout/sidebar/sidebar-nav';

export const SidebarMobile = () => {
  const [isSidemenuOpen, setIsSidemenuOpen] = useState(false);

  return (
    <div className="flex-1">
      <Sheet open={isSidemenuOpen} onOpenChange={setIsSidemenuOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <MenuIcon className="w-5 h-5 cursor-pointer" />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="left"
          className="flex flex-col overflow-y-auto py-4 px-0"
        >
          <SidebarNav onCloseSidemenu={() => setIsSidemenuOpen(false)} />
        </SheetContent>
      </Sheet>
    </div>
  );
};
