import { Dispatch, SetStateAction } from 'react';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from './shadcn-ui/select';
import { useTranslation } from 'react-i18next';

interface SelectShiftProps {
  shift: RestaurantShift;
  setShift: Dispatch<SetStateAction<RestaurantShift>>;
  className?: string;
}

export const SelectShift = ({
  shift,
  setShift,
  className,
}: SelectShiftProps) => {
  const { t } = useTranslation();
  return (
    <Select
      value={shift}
      onValueChange={(shift) => setShift(shift as RestaurantShift)}
    >
      <SelectTrigger className={className}>
        {shift}. {t('menu.shift')}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="1">1. {t('menu.shift')}</SelectItem>
          <SelectItem value="2">2. {t('menu.shift')}</SelectItem>
          <SelectItem value="3">3. {t('menu.shift')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
