import { Spinner } from '@/components/shared/spinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

interface SelectRoles {
  value: string;
  name: string;
}

interface SelectRoleProps {
  value: string;
  placeholder: string;
  disabled?: boolean;
  isLoading?: boolean;
  roles: SelectRoles[];
  onValueChange: (value: string) => void;
}

export const SelectRole = ({
  value,
  placeholder = '',
  disabled = false,
  isLoading = false,
  roles,
  onValueChange,
}: SelectRoleProps) => {
  return (
    <Select value={value} onValueChange={onValueChange} disabled={disabled}>
      <SelectTrigger>
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <SelectValue placeholder={placeholder} />
        )}
      </SelectTrigger>

      <SelectContent>
        {roles.map(({ name, value }) => (
          <SelectItem key={value} value={value}>
            {name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
