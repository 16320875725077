import { QrCode } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Card, CardTitle } from '@/components/shared/shadcn-ui/card';

export const TrackIdleCard = () => {
  const { t } = useTranslation();
  return (
    <Card className="p-4">
      <QrCode className="h-16 w-16 mb-4" />
      <CardTitle>{t('track.idle.title')}</CardTitle>
      <div className="mt-4 space-y-2">
        <p className="text-sm">{t('track.idle.description')}</p>
        <p className="text-sm">{t('track.idle.user-directive')}</p>
      </div>
    </Card>
  );
};
