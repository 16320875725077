import { toast } from 'sonner';
import { Image } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useState } from 'react';
import { UserRestaurant } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useEditRestaurantInformationMutation,
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
} from '@durma-soft/gros-sdk';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { InputAddon } from '@/components/shared/input-addon';
import { TimePicker } from '@/components/shared/time-picker';
import { Button } from '@/components/shared/shadcn-ui/button';
import { CustomFileInput } from '@/components/shared/custom-file-input';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { Time } from '@/types/general';
import { formatTime } from '@/utils/helpers';

interface FormState {
  name: string;
  phone_number: string;
  street: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  logo: File | null;
  time: Time;
}

interface RestaurantInformationProps {
  restaurant: UserRestaurant;
}

export const EditRestaurantInformationForm = ({
  restaurant,
}: RestaurantInformationProps) => {
  const { t } = useTranslation();

  const [hour, minute] = restaurant.order_cutoff_time.split(':');

  const [formData, setFormData] = useState<FormState>({
    name: restaurant.name,
    phone_number:
      parsePhoneNumber(restaurant.phone_number)?.formatNational() ||
      restaurant.phone_number ||
      '',
    street: restaurant.street,
    city: restaurant.city,
    state: restaurant.state,
    country: restaurant.country,
    logo: restaurant.logo as File | null,
    time: { hour, minute },
  });

  const [countryCode, setCountryCode] = useState<CountryCode>(
    parsePhoneNumber(restaurant.phone_number)?.country ||
      import.meta.env.VITE_DEFAULT_COUNTRY_CODE ||
      'BA',
  );

  const uid = useId();
  const editRestaurantInformation = useEditRestaurantInformationMutation();

  const handleTimeChange = (hour: string, minute: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: { hour, minute },
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await editRestaurantInformation.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || undefined,
        street: formData.street || undefined,
        city: formData.city || undefined,
        state: formData.state || undefined,
        country: formData.country || undefined,
        logo: formData.logo,
        order_cutoff_time: formatTime(formData.time),
      });
      toast.success(t('settings.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('settings.edit-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-start gap-8">
        {/* Details */}
        <div className="grid gap-4">
          {/* Logo */}
          <div className="flex flex-col w-1/2 gap-4">
            <div className="w-32 h-32">
              {!formData.logo && (
                <div className="inline-flex items-center justify-center w-full h-full border rounded-md border-border">
                  <Image className="w-6 h-6" />
                </div>
              )}
              {formData.logo && (
                <div>
                  <img
                    src={
                      typeof formData.logo === 'string'
                        ? formData.logo
                        : URL.createObjectURL(formData.logo)
                    }
                    height="128"
                    width="128"
                    className="object-contain object-center w-32 h-32 rounded-md"
                  />
                </div>
              )}
            </div>
            <CustomFileInput
              label={t('common.add-logo')}
              hasDeleteButton={Boolean(formData.logo)}
              onDelete={() => setFormData({ ...formData, logo: null })}
              handleFile={(file) => {
                setFormData({ ...formData, logo: file });
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-6">
            {/* Name */}
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
              <div className="flex items-stretch w-full">
                <Input
                  id={uid + '-name'}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  placeholder={t('common.name-placeholder')}
                />
              </div>
            </div>
            {/* Phone number */}
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-phone-number'}>
                {t('common.phone-number')}
              </Label>
              <div className="flex items-stretch">
                <InputAddon position="left">
                  <SelectCountryCallingCode
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value as CountryCode);
                      setFormData({
                        ...formData,
                        phone_number: '',
                      });
                    }}
                  />
                </InputAddon>
                <Input
                  className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                  id={uid + '-phone-number'}
                  value={formData.phone_number}
                  placeholder={phoneNumberInputPlaceholder[countryCode]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      phone_number: asYouTypePhoneNumber(
                        e.target.value,
                        countryCode,
                      ),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="my-2 text-lg">{t('common.address')}</h3>
            <div className="grid grid-cols-2 gap-6">
              {/* Street */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-street'}>
                  {t('common.address-details.street')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-street'}
                    value={formData.street || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                    placeholder={t('common.address-details.street-placeholder')}
                  />
                </div>
              </div>
              {/* City */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-city'}>
                  {t('common.address-details.city')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-city'}
                    value={formData.city || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                    placeholder={t('common.address-details.city-placeholder')}
                  />
                </div>
              </div>
              {/* State */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-state'}>
                  {t('common.address-details.state')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-state'}
                    value={formData.state || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, state: e.target.value })
                    }
                    placeholder={t('common.address-details.state-placeholder')}
                  />
                </div>
              </div>
              {/* Country */}
              <div className="flex flex-col gap-2">
                <Label htmlFor={uid + '-country'}>
                  {t('common.address-details.country')}
                </Label>
                <div className="flex items-stretch w-full">
                  <Input
                    id={uid + '-country'}
                    value={formData.country || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, country: e.target.value })
                    }
                    placeholder={t(
                      'common.address-details.country-placeholder',
                    )}
                  />
                </div>
              </div>
              {/* Time */}
              <div>
                <Label>{t('settings.order-deadline')}</Label>
                <TimePicker
                  time={formData.time}
                  handleTimeChange={handleTimeChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button type="submit" isLoading={editRestaurantInformation.isPending}>
          {t('common.save-edit')}
        </Button>
      </div>
    </form>
  );
};
