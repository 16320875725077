import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import { OrdersByEmployeeReportOrder } from '@durma-soft/gros-sdk/dist/types/reports';

import { DataTable } from '@/components/shared/data-table';

import { columns } from '@/config/report/orders-by-employee';

interface OrdersByEmployeeProps {
  orders: Record<RestaurantShift, OrdersByEmployeeReportOrder[]>;
  isTableSyncReference?: boolean;
}

export const OrdersByEmployee = ({
  orders,
  isTableSyncReference,
}: OrdersByEmployeeProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(() => columns(t), [t]);

  return (
    <div className="flex flex-col gap-6">
      {Object.entries(orders!).map(([key, value], index) => {
        if (value.length === 0) return;
        return (
          <div key={key}>
            <h5 className="mb-3 text-lg">
              {key}. {t('menu.shift').toLowerCase()}
            </h5>
            <DataTable
              isSyncReference={isTableSyncReference && index === 0}
              data={value}
              columns={memoizedColumns}
            />
          </div>
        );
      })}
    </div>
  );
};
