import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeOff } from 'lucide-react';
import { FormEvent, useId, useMemo, useState } from 'react';
import { useLoginMutation, ValidationError } from '@durma-soft/gros-sdk';

import { LOCAL_STORAGE_TOKEN_KEY } from '@/config/global/storage';

import { Link } from '@/components/shared/link';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { InputAddon } from '@/components/shared/input-addon';
import { Button } from '@/components/shared/shadcn-ui/button';

import { isNotEmpty } from '@/utils/helpers';

const initialFormData = {
  username: '',
  password: '',
};

const Login = () => {
  const id = useId();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const loginMutation = useLoginMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(formData.username) && isNotEmpty(formData.password);
  }, [formData]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValid) return;
    try {
      const { token } = await loginMutation.mutateAsync(formData);
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
      toast.success(t('auth.login-success'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(t(error.message));
      }
      toast.error(t('auth.login-error'));
    }
  };

  return (
    <div className="flex items-center justify-center py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t('auth.login')}</h1>
          <p className="text-balance text-muted-foreground">
            {t('auth.login-details')}
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor={`${id}-username`}>{t('common.username')}</Label>
              <Input
                id={`${id}-username`}
                type="text"
                required
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor={`${id}-password`}>{t('common.password')}</Label>
              </div>
              <div className="flex items-stretch">
                <Input
                  id={`${id}-password`}
                  type={showPassword ? 'text' : 'password'}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  className="flex-1 border-r-0 rounded-tr-none rounded-br-none"
                />
                <InputAddon
                  position="right"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeIcon className="w-4 h-4" />
                  ) : (
                    <EyeOff className="w-4 h-4" />
                  )}
                </InputAddon>
              </div>
            </div>
            <Link
              to="/auth/forgot-password"
              className="inline-block ml-auto text-sm underline"
            >
              {t('auth.forgot-password')}
            </Link>
            <Button
              disabled={!isValid || loginMutation.isPending}
              isLoading={loginMutation.isPending}
              type="submit"
              className="w-full"
            >
              {t('auth.login')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
