import { useTranslation } from 'react-i18next';
import {
  Locale,
  useAuthUser,
  useUpdateCurrentUserLocaleMutation,
} from '@durma-soft/gros-sdk';

import { SelectLanguage } from '@/components/shared/select-language';

import { UserAccount } from '@/layout/header/user-account';
import { SidebarMobile } from '@/layout/sidebar/sidebar-mobile';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuthUser();
  const updateUserLocaleMutation = useUpdateCurrentUserLocaleMutation();

  return (
    <header className="flex justify-end h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-15 lg:px-6 print:hidden">
      <SidebarMobile />
      <div>
        <SelectLanguage
          value={user.locale}
          isLoading={updateUserLocaleMutation.isPending}
          placeholder={t(`languages.${user.locale as Locale}`)}
          onValueChange={(lang) => {
            i18n.changeLanguage(lang);
            updateUserLocaleMutation.mutate({ locale: lang as Locale });
          }}
        />
      </div>
      <UserAccount />
    </header>
  );
};

export default Header;
