import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useId } from 'react';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';

import { CompanyLocationFormData } from '@/types/companies';

interface CompanyLocationAddressProps {
  formData: CompanyLocationFormData;
  setFormData: Dispatch<SetStateAction<CompanyLocationFormData>>;
}

export const CompanyLocationAddress = ({
  formData,
  setFormData,
}: CompanyLocationAddressProps) => {
  const uid = useId();
  const { t } = useTranslation();

  return (
    <div className="grid gap-4">
      {/* Street */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-street'}>
          {t('common.address-details.street')}
        </Label>
        <Input
          id={uid + '-street'}
          value={formData.street || ''}
          onChange={(e) => setFormData({ ...formData, street: e.target.value })}
          placeholder={t('common.address-details.street-placeholder')}
        />
      </div>
      {/* City */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-city'}>
          {t('common.address-details.city')}
        </Label>
        <Input
          id={uid + '-city'}
          value={formData.city || ''}
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          placeholder={t('common.address-details.city-placeholder')}
        />
      </div>
      {/* ZIP */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-zip'}>{t('common.address-details.zip')}</Label>
        <Input
          id={uid + '-zip'}
          value={formData.zip || ''}
          onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
          placeholder={t('common.address-details.zip-placeholder')}
        />
      </div>
      {/* State */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-state'}>
          {t('common.address-details.state')}
        </Label>
        <Input
          id={uid + '-state'}
          value={formData.state || ''}
          onChange={(e) => setFormData({ ...formData, state: e.target.value })}
          placeholder={t('common.address-details.state-placeholder')}
        />
      </div>
      {/* Country */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-country'}>
          {t('common.address-details.country')}
        </Label>
        <Input
          id={uid + '-country'}
          value={formData.country || ''}
          onChange={(e) =>
            setFormData({ ...formData, country: e.target.value })
          }
          placeholder={t('common.address-details.country-placeholder')}
        />
      </div>
    </div>
  );
};
