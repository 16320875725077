/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import { Pencil, Trash2 } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import { CompanyEmployee } from '@durma-soft/gros-sdk/dist/types/company';
import {
  useAuthUserCompany,
  useDeleteCompanyEmployeeMutation,
} from '@durma-soft/gros-sdk';

import { Badge } from '@/components/shared/shadcn-ui/badge';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { COMPANY_EMPLOYEE_ROLE } from '@/config/global/roles';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<CompanyEmployee>[] => {
  return [
    {
      accessorKey: 'username',
      header: t('common.username'),
    },
    {
      accessorKey: 'first_name',
      header: t('common.first-name'),
    },
    {
      accessorKey: 'last_name',
      header: t('common.last-name'),
    },
    {
      accessorKey: 'email',
      header: t('common.email'),
    },
    {
      accessorKey: 'role',
      header: t('roles.title'),
      cell: (cellData) => {
        const { role } = cellData.row.original;
        return <>{COMPANY_EMPLOYEE_ROLE(t)[role]}</>;
      },
    },
    {
      accessorKey: 'sex',
      header: t('common.sex'),
      cell: (cellData) => {
        const { sex } = cellData.row.original;

        if (sex === 'male')
          return (
            <Badge className="bg-blue-500 hover:bg-blue-500">
              {t('common.male')}
            </Badge>
          );

        if (sex === 'female')
          return (
            <Badge className="bg-rose-500 hover:bg-rose-500">
              {t('common.female')}
            </Badge>
          );

        return null;
      },
    },
    {
      accessorKey: 'can_change_location',
      header: t('employee.can-change-location'),
      cell: (cellData) => {
        const canChangeLocation = cellData.row.original.can_change_location;

        if (!canChangeLocation)
          return (
            <Badge className="bg-red-500 hover:bg-red-500">
              {t('employee.not-possible-change-location')}
            </Badge>
          );
        return (
          <Badge className="bg-green-500 hover:bg-green-500">
            {t('employee.possible-change-location')}
          </Badge>
        );
      },
    },
    {
      accessorKey: 'max_daily_orders',
      header: t('employee.max-daily-orders'),
    },
    {
      accessorKey: 'edit',
      header: '',
      cell: (cellData) => {
        const { locationId } = useParams();
        const company = useAuthUserCompany();
        const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
          useState(false);

        const { isPending, mutateAsync } = useDeleteCompanyEmployeeMutation();

        const deleteCompanyEmployee = async () => {
          try {
            await mutateAsync({
              company_id: company.id,
              employee_id: cellData.row.original.id,
            });
            toast.success(t('employee.delete-success-msg'));
          } catch (error) {
            toast.error(t('employee.delete-error-msg'));
          } finally {
            setIsConfirmationModalOpen(false);
          }
        };
        return (
          <>
            <TooltipProvider delayDuration={100}>
              <div className="flex items-center justify-center gap-5">
                {/* Edit */}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      to={
                        locationId
                          ? `/company/locations/${locationId}/employees/edit/${cellData.row.original.id}`
                          : `/company/employees/edit/${cellData.row.original.id}`
                      }
                      className="flex items-center justify-center text-gray-700 "
                    >
                      <Pencil className="w-5 h-5" />
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>{t('employee.edit-details')}</TooltipContent>
                </Tooltip>
                {/* Delete */}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      className="flex items-center justify-center text-gray-700 p-0"
                      onClick={() => setIsConfirmationModalOpen(true)}
                    >
                      <Trash2 className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('common.delete')}</TooltipContent>
                </Tooltip>
              </div>
            </TooltipProvider>
            <ConfirmationModal
              isLoading={isPending}
              isModalOpen={isConfirmationModalOpen}
              confirmationText={t('common.delete')}
              title={t('employee.delete')}
              description={t('employee.delete-description', {
                employee: (
                  cellData.row.original.first_name +
                  cellData.row.original.last_name
                ).toLowerCase(),
              })}
              onConfirm={deleteCompanyEmployee}
              onCancel={() => setIsConfirmationModalOpen(false)}
              closeModal={() => setIsConfirmationModalOpen(false)}
            />
          </>
        );
      },
    },
  ];
};
