import { TType } from '@/types/general';

export const ingredientMeasureUnits = (t: TType) => [
  { value: 'g', name: t('common.measure-unit.g') },
  { value: 'ml', name: t('common.measure-unit.ml') },
  { value: 'unit', name: t('common.measure-unit.unit') },
];

export const ingredientPreferredMeasureUnits = (t: TType) => {
  return {
    unit: [
      {
        name: t('common.measure-unit.unit'),
        value: 'unit',
      },
    ],
    g: [
      {
        name: t('common.measure-unit.g'),
        value: 'g',
      },
      {
        name: t('common.measure-unit.kg'),
        value: 'kg',
      },
    ],
    ml: [
      {
        name: t('common.measure-unit.ml'),
        value: 'ml',
      },
      {
        name: t('common.measure-unit.l'),
        value: 'l',
      },
      {
        name: t('common.measure-unit.dl'),
        value: 'dl',
      },
    ],
  };
};
