import { Route, Routes } from 'react-router-dom';

import Layout from '@/layout';

import AppModeProvider from '@/providers/AppModeProvider';

import { ProtectedRoute } from '@//middleware/protected-route';
import { NonProtectedRoute } from '@/middleware/non-protected-route';
import { UserCanAccessTrackMiddleware } from '@/middleware/user-can-access-track-middleware';
import { UserIsCompanyAdministratorMiddleware } from '@/middleware/user-is-company-administrator-middleware';
import { UserHasCompanyOrRestaurantMiddleware } from '@/middleware/user-has-company-or-restaurant-middleware';

import Auth from '@/pages/auth/index';
import Login from '@/pages/auth/login';
import ResetPassword from '@/pages/auth/reset-password';
import ForgotPassword from '@/pages/auth/forgot-password';

import Restaurant from '@/pages/restaurant';
import RestaurantMenus from '@/pages/restaurant/menus';
import RestaurantFoods from '@/pages/restaurant/foods';
import RestaurantSettings from '@/pages/restaurant/settings';
import RestaurantCompanies from '@/pages/restaurant/companies';
import RestaurantEmployees from '@/pages/restaurant/employees';
import RestaurantMenuTypes from '@/pages/restaurant/menu-types';
import RestaurantIngredients from '@/pages/restaurant/ingredients';
import RestaurantCompanyEmployees from '@/pages/restaurant/company-employees';
import RestaurantCompanyLocations from '@/pages/restaurant/company-locations';
import RestaurantCompanyLocationEmployees from '@/pages/restaurant/company-location-employees';

import Reports from '@/pages/report';
import FoodsReport from '@/pages/report/foods';
import OrdersReport from '@/pages/report/orders';
import PackagesReport from '@/pages/report/packages';
import InvoicingReport from '@/pages/report/invoicing';
import RestaurantReport from '@/pages/report/restaurants';
import IngredientsReport from '@/pages/report/ingredients';
import SubstituteMealsReport from '@/pages/report/substitute-meals';
import RestaurantCustomerOrders from '@/pages/report/restaurant-customer-orders';

import Company from '@/pages/company';
import CompanyTrack from '@/pages/company/track';
import CompanyEmployees from '@/pages/company/employees';
import CompanyLocations from '@/pages/company/locations';
import CompanyLocationEmployees from '@/pages/company/location-employees';

import Index from '@/pages/index';
import NotFound from '@/pages/404';
import NotAllowed from '@/pages/not-allowed';

function App() {
  return (
    <Routes>
      {/* Non protected route */}
      <Route
        path="/auth"
        element={
          <NonProtectedRoute>
            <Auth />
          </NonProtectedRoute>
        }
      >
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      {/* Protected route */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <UserHasCompanyOrRestaurantMiddleware>
              <AppModeProvider>
                <Layout />
              </AppModeProvider>
            </UserHasCompanyOrRestaurantMiddleware>
          </ProtectedRoute>
        }
      >
        {/* Restaurant */}
        <Route path="restaurant" element={<Restaurant />}>
          <Route path="menus" element={<RestaurantMenus />}>
            <Route path="create" element={<RestaurantMenus />} />
            <Route path="edit/:menuId" element={<RestaurantMenus />} />
            <Route path="copy" element={<RestaurantMenus />} />
          </Route>

          <Route path="employees" element={<RestaurantEmployees />}>
            <Route path="create" element={<RestaurantEmployees />} />
            <Route path="edit/:employeeId" element={<RestaurantEmployees />} />
          </Route>

          <Route path="ingredients" element={<RestaurantIngredients />}>
            <Route path="create" element={<RestaurantIngredients />} />
            <Route
              path="edit/:ingredientId"
              element={<RestaurantIngredients />}
            />
            <Route
              path="edit-quantity/:ingredientId"
              element={<RestaurantIngredients />}
            />
          </Route>

          <Route path="foods" element={<RestaurantFoods />}>
            <Route path="create" element={<RestaurantFoods />} />
            <Route path="edit/:foodId" element={<RestaurantFoods />} />
          </Route>

          <Route path="menu-types" element={<RestaurantMenuTypes />}>
            <Route path="create" element={<RestaurantMenuTypes />} />
            <Route path="edit/:menuTypeId" element={<RestaurantMenuTypes />} />
          </Route>

          <Route path="settings" element={<RestaurantSettings />} />
          {/* Reports */}
          <Route path="reports" element={<Reports />}>
            <Route path="ingredients" element={<IngredientsReport />} />
            <Route path="foods" element={<FoodsReport />} />
            <Route path="orders" element={<OrdersReport />} />
            <Route path="packages" element={<PackagesReport />} />
            <Route
              path="restaurant-customer-orders"
              element={<RestaurantCustomerOrders />}
            />
            <Route
              path="substitute-meals"
              element={<SubstituteMealsReport />}
            />
            <Route path="restaurants" element={<RestaurantReport />} />
            <Route path="invoicing" element={<InvoicingReport />} />
          </Route>

          {/* Restaurant company */}
          <Route path="companies" element={<RestaurantCompanies />}>
            <Route path="create" element={<RestaurantCompanies />} />
            <Route path="edit/:companyId" element={<RestaurantCompanies />} />
          </Route>

          <Route
            path="companies/:companyId/employees"
            element={<RestaurantCompanyEmployees />}
          >
            <Route path="create" element={<RestaurantCompanyEmployees />} />
            <Route
              path="edit/:employeeId"
              element={<RestaurantCompanyEmployees />}
            />
          </Route>

          <Route
            path="companies/:companyId/locations"
            element={<RestaurantCompanyLocations />}
          >
            <Route path="create" element={<RestaurantCompanyLocations />} />
            <Route
              path="edit/:locationId"
              element={<RestaurantCompanyLocations />}
            />
          </Route>

          {/* Company location employees */}
          <Route
            path="companies/:companyId/locations/:locationId/employees"
            element={<RestaurantCompanyLocationEmployees />}
          >
            <Route
              path="create"
              element={<RestaurantCompanyLocationEmployees />}
            />
            <Route
              path="edit/:employeeId"
              element={<RestaurantCompanyLocationEmployees />}
            />
          </Route>
        </Route>

        {/* Company */}
        <Route path="company" element={<Company />}>
          <Route path="track" element={<UserCanAccessTrackMiddleware />}>
            <Route path="" element={<CompanyTrack />} />
          </Route>
          <Route path="" element={<UserIsCompanyAdministratorMiddleware />}>
            {/* Locations */}
            <Route path="locations" element={<CompanyLocations />}>
              <Route path="create" element={<CompanyLocations />} />
              <Route path="edit/:locationId" element={<CompanyLocations />} />
            </Route>

            {/* Employees */}
            <Route path="employees" element={<CompanyEmployees />}>
              <Route path="create" element={<CompanyEmployees />} />
              <Route path="edit/:employeeId" element={<CompanyEmployees />} />
            </Route>

            {/* Location employees */}
            <Route
              path="locations/:locationId/employees"
              element={<CompanyLocationEmployees />}
            >
              <Route path="create" element={<CompanyLocationEmployees />} />
              <Route
                path="edit/:employeeId"
                element={<CompanyLocationEmployees />}
              />
            </Route>
          </Route>
        </Route>

        {/* Not allowed */}
        <Route path="/not-allowed" element={<NotAllowed />} />
        {/* Index */}
        <Route path="/" element={<Index />} />
        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
