import { useDebounce } from 'react-use';
import { useState, ChangeEvent } from 'react';

export const useDebouncedSearch = (initialValue = '', delay = 300) => {
  const [searchInput, setSearchInput] = useState(initialValue);
  const [debouncedSearchInput, setDebouncedSearchInput] =
    useState(initialValue);

  useDebounce(
    () => {
      setDebouncedSearchInput(searchInput);
    },
    delay,
    [searchInput],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  return { searchInput, debouncedSearchInput, handleChange };
};
