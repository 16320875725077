import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  useAuthUserCompany,
  useGetCompanyLocationsQuery,
} from '@durma-soft/gros-sdk';

import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditCompanyLocationModal } from '@/components/company/edit-company-location-modal';
import { CreateCompanyLocationModal } from '@/components/company/create-company-location-modal';

import { columns } from '@/config/company/locations';

const CompanyLocations = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const company = useAuthUserCompany();

  const { data, isLoading } = useGetCompanyLocationsQuery({
    company_id: company.id,
  });

  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t('company-locations.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('company-locations.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/company/locations/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('company-locations.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoading}
          />
        </div>
      </div>
      <CreateCompanyLocationModal />
      {Boolean(locationId) && <EditCompanyLocationModal key={locationId} />}
    </>
  );
};

export default CompanyLocations;
