import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantMenuForm } from '@/components/menus/create-restaurant-menu-form';

interface CreateRestaurantMenuModalProps {
  date: string;
}

export const CreateRestaurantMenuModal = ({
  date,
}: CreateRestaurantMenuModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate(`/restaurant/menus?date=${date}`);

  const isModalOpen = Boolean(useMatch({ path: `/restaurant/menus/create` }));

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('menu.create')}
      description={t('menu.create-details')}
      className="flex flex-col px-6 overflow-y-hidden"
    >
      <CreateRestaurantMenuForm closeModal={closeModal} />
    </Modal>
  );
};
