import { format } from 'date-fns';
import { useState, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDatePickerState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get('date') || format(Date.now(), 'yyyy-MM-dd');

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useLayoutEffect(() => {
    if (!searchParams.has('date')) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        date: format(Date.now(), 'yyyy-MM-dd'),
      });
    }
  }, [searchParams, setSearchParams]);

  const setDate = (newDate: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      date: newDate,
    });
  };

  return {
    date,
    datePickerOpen,
    setDate,
    setDatePickerOpen,
  };
};
