import { forwardRef, ForwardRefExoticComponent, useMemo } from 'react';
import {
  LinkProps,
  Link as ReactRouterLink,
  useSearchParams,
} from 'react-router-dom';

interface CustomLinkProps {
  persistQueryParams?: boolean;
}

export const Link: ForwardRefExoticComponent<
  LinkProps & CustomLinkProps & React.RefAttributes<HTMLAnchorElement>
> = forwardRef(({ persistQueryParams = true, to: toProp, ...props }, ref) => {
  const [searchParams] = useSearchParams();

  const to = useMemo(() => {
    if (!persistQueryParams) return toProp;
    const newSearchParams = new URLSearchParams(searchParams);

    if (typeof toProp === 'string' && toProp.includes('?')) {
      const searchString = toProp.split('?')[1];
      const searchParams = new URLSearchParams(searchString);
      searchParams.forEach((value, key) => {
        newSearchParams.set(key, value);
      });
    } else if (typeof toProp.search === 'string') {
      const searchParams = new URLSearchParams(toProp.search);
      searchParams.forEach((value, key) => {
        newSearchParams.set(key, value);
      });
    }

    const toWithoutSearch =
      typeof toProp === 'string' ? toProp.split('?')[0] : toProp.pathname;
    return `${toWithoutSearch}?${newSearchParams.toString()}`;
  }, [toProp, persistQueryParams, searchParams]);

  return <ReactRouterLink ref={ref} {...props} to={to} />;
});
