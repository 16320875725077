import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetRestaurantCompanyEmployeesQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { NavLink } from '@/components/shared/nav-link';
import { SearchInput } from '@/components/shared/search-input';
import { useDebouncedSearch } from '@/hooks/use-debounced-search';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { CreateRestaurantCompanyEmployeeModal } from '@/components/companies/create-restaurant-company-employee-modal';
import { EditRestaurantCompanyEmployeeModal } from '@/components/companies/edit-restaurant-company-employee-modal';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/shared/shadcn-ui/breadcrumb';

import { columns } from '@/config/restaurant/company-employees';

const RestaurantCompanyEmployees = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { companyId, employeeId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  const { data, isLoading } = useGetRestaurantCompanyEmployeesQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
    page: pagination.pageIndex + 1,
    q: debouncedSearchInput,
  });

  return (
    <>
      <Helmet>
        <title>{t('employee.title')}</title>
      </Helmet>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <NavLink to="/restaurant/companies">{t('company.title')}</NavLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <NavLink
              to={`/restaurant/companies/${companyId}/employees`}
              className={({ isActive }) =>
                isActive ? 'text-primary' : 'text-yellow-300'
              }
            >
              {t('employee.title')}
            </NavLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('employee.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/restaurant/companies/${companyId}/employees/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('employee.create')}
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            <SearchInput
              className="self-start p-0"
              placeholder={t('common.search-placeholder')}
              searchInput={searchInput}
              handleChange={(event) => {
                handleChange(event);
                setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
              }}
            />
            <DataTable
              columns={memoizedColumns}
              data={data?.data}
              pageCount={data?.total_pages}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              columnVisibility={{
                edit: hasEditPermission,
              }}
            />
          </div>
        </div>
      </div>
      <CreateRestaurantCompanyEmployeeModal />
      {Boolean(employeeId) && (
        <EditRestaurantCompanyEmployeeModal key={employeeId} />
      )}
    </>
  );
};

export default RestaurantCompanyEmployees;
