import { TType } from '@/types/general';
import { SelectList } from '@/types/menus';

export const shifts = (t: TType): SelectList[] => [
  {
    id: 1,
    label: t('shifts.first'),
  },
  {
    id: 2,
    label: t('shifts.second'),
  },
  {
    id: 3,
    label: t('shifts.third'),
  },
];

// Name because of other possible options
export const options = (t: TType): SelectList[] => [
  {
    id: 1,
    label: t('menu.activate-all'),
    value: 'activate',
  },
  {
    id: 2,
    label: t('menu.deactivate-all'),
    value: 'deactivate',
  },
];
