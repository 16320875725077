import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { Locale } from '@durma-soft/gros-sdk';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import { Calendar } from '@/components/shared/shadcn-ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';

import { cn } from '@/utils/helpers';
import { locales } from '@/config/global/languages';

interface DatePickerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => unknown;
  date?: Date;
  setDate: (state: Date | undefined) => unknown;
}

export function DatePicker({
  date,
  setDate,
  open,
  onOpenChange,
}: DatePickerProps) {
  const { t, i18n } = useTranslation();
  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-max justify-start text-left font-normal',
            !date && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="w-4 h-4 mr-2" />
          {date ? (
            format(date, 'PPP', { locale: locales[i18n.language as Locale] })
          ) : (
            <span>{t('common.choose-date')}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          ISOWeek
          defaultMonth={date}
        />
      </PopoverContent>
    </Popover>
  );
}
