import { Outlet } from 'react-router-dom';

const Main = () => {
  return (
    <main className="flex flex-col flex-1 gap-4 p-4 overflow-y-auto lg:gap-6 lg:p-6 relative">
      <Outlet />
    </main>
  );
};

export default Main;
