import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useGetAllRestaurantIngredientsQuery } from '@durma-soft/gros-sdk';
import { ShortRestaurantIngredient } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';
import { ShortRestaurantIngredientWithQuantity } from '@/types/foods';
// import { commandScore } from '@/utils/command-score';

interface IngredientComboboxProps {
  restaurantId: number;
  selectedIngredients: ShortRestaurantIngredientWithQuantity[];
  onUpdateIngredients: (
    ingredients: ShortRestaurantIngredientWithQuantity[],
  ) => unknown;
}

export const IngredientCombobox = ({
  restaurantId,
  selectedIngredients,
  onUpdateIngredients,
}: IngredientComboboxProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data: ingredients, isLoading } =
    useGetAllRestaurantIngredientsQuery(restaurantId);

  if (isLoading) return <Spinner />;

  const toggleIngredient = (ingredient: ShortRestaurantIngredient) => {
    if (selectedIngredients.some((i) => i.id === ingredient.id)) {
      onUpdateIngredients(
        selectedIngredients.filter((i) => i.id !== ingredient.id),
      );
    } else {
      onUpdateIngredients([
        ...selectedIngredients,
        { ...ingredient, quantity: 0 },
      ]);
    }
  };

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedIngredients.length > 0
            ? `${selectedIngredients.length} ${t('common.chosen')}`
            : t('food.choose-ingredients')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={t('common.search-placeholder')} />
          <CommandEmpty>{t('food.ingredients-not-found')}</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {ingredients &&
                ingredients.map((ingredient) => (
                  <CommandItem
                    onSelect={() => {
                      toggleIngredient(ingredient);
                    }}
                    key={ingredient.id}
                    value={String(ingredient.name) + String(ingredient.id)}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedIngredients.some((i) => i.id === ingredient.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {ingredient.name}
                  </CommandItem>
                ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
