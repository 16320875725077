import { ColumnDef } from '@tanstack/react-table';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<NumberOfOrdersPerEmployeeReportUser>[] => [
  {
    accessorKey: 'first_name',
    header: t('common.first-name'),
  },
  {
    accessorKey: 'last_name',
    header: t('common.last-name'),
  },
  {
    header: t('reports.restaurant-customers.ordered'),
    cell: (cellData) => <>{cellData.row.original.ordered}</>,
  },
  {
    header: t('reports.restaurant-customers.picked-up'),
    cell: (cellData) => (
      <>
        {cellData.row.original.picked_up
          ? cellData.row.original.picked_up
          : '/'}
      </>
    ),
  },
];
