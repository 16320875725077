import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  LocationType,
  useAuthUserCompany,
  useCreateCompanyLocationMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';

import { isNotEmpty } from '@/utils/helpers';
import { SingleCompanyLocationFormData } from '@/types/companies';
import { locationDeliveryTypes } from '@/config/restaurant/company-locations';

interface CreateCompanyLocationProps {
  closeModal: () => unknown;
}

export const CreateCompanyLocationForm = ({
  closeModal,
}: CreateCompanyLocationProps) => {
  const uid = useId();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<SingleCompanyLocationFormData>({
    name: '',
    street: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    location_type: 'direct',
  });

  const company = useAuthUserCompany();
  const createCompanyLocation = useCreateCompanyLocationMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name);
  }, [formData.name]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createCompanyLocation.mutateAsync({
        company_id: company.id,
        name: formData.name,
        street: formData.street,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        country: formData.country,
        location_type: formData.location_type || 'direct',
      });
      closeModal();
      toast.success(t('company-locations.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company-locations.create-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="grid gap-4 py-4">
          {/* Name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
            <Input
              required
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
          {/* Street */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-street'}>
              {t('common.address-details.street')}
            </Label>
            <Input
              id={uid + '-street'}
              value={formData.street || ''}
              onChange={(e) =>
                setFormData({ ...formData, street: e.target.value })
              }
              placeholder={t('common.address-details.street-placeholder')}
            />
          </div>
          {/* City */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-city'}>
              {t('common.address-details.city')}
            </Label>
            <Input
              id={uid + '-city'}
              value={formData.city || ''}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              placeholder={t('common.address-details.city-placeholder')}
            />
          </div>
          {/* ZIP */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-zip'}>
              {t('common.address-details.zip')}
            </Label>
            <Input
              id={uid + '-zip'}
              value={formData.zip || ''}
              onChange={(e) =>
                setFormData({ ...formData, zip: e.target.value })
              }
              placeholder={t('common.address-details.zip-placeholder')}
            />
          </div>
          {/* State */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-state'}>
              {t('common.address-details.state')}
            </Label>
            <Input
              id={uid + '-state'}
              value={formData.state || ''}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              placeholder={t('common.address-details.state-placeholder')}
            />
          </div>
          {/* Country */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-country'}>
              {t('common.address-details.country')}
            </Label>
            <Input
              id={uid + '-country'}
              value={formData.country || ''}
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
              placeholder={t('common.address-details.country-placeholder')}
            />
          </div>
          {/* Location type */}
          <div className="flex flex-col gap-2">
            <Label>{t('common.location-type')}</Label>
            <RadioGroup
              value={formData.location_type || 'direct'}
              onValueChange={(e) =>
                setFormData((prevData) => {
                  return { ...prevData, location_type: e as LocationType };
                })
              }
            >
              {locationDeliveryTypes(t)?.map(({ value, label }) => (
                <div key={value} className="flex items-center space-x-2">
                  <RadioGroupItem value={value} id={uid + value} />
                  <Label htmlFor={uid + value}>{label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createCompanyLocation.isPending}
        >
          {t('company-locations.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
