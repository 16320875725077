import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetRestaurantIngredientsQuery,
} from '@durma-soft/gros-sdk';
import { useMatch, useParams, useSearchParams } from 'react-router-dom';

import { Link } from '@/components/shared/link';
import { DataTable } from '@/components/shared/data-table';
import { DatePicker } from '@/components/shared/date-picker';
import { SearchInput } from '@/components/shared/search-input';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditRestaurantIngredientModal } from '@/components/ingredients/edit-restaurant-ingredient-modal';
import { CreateRestaurantIngredientModal } from '@/components/ingredients/create-restaurant-ingredient-modal';
import { EditRestaurantIngredientQuantityModal } from '@/components/ingredients/edit-restaurant-ingredient-quantity-modal';

import { columns } from '@/config/restaurant/ingredients';

import { useDebouncedSearch } from '@/hooks/use-debounced-search';

const RestaurantIngredients = () => {
  const { t } = useTranslation();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { ingredientId } = useParams();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get('date') || format(Date.now(), 'yyyy-MM-dd');

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  useLayoutEffect(() => {
    if (!searchParams.has('date')) {
      setSearchParams({
        ...searchParams,
        date: format(Date.now(), 'yyyy-MM-dd'),
      });
    }
  }, [searchParams, setSearchParams]);

  const setDate = (newDate: string) => {
    setSearchParams({
      ...searchParams,
      date: newDate,
    });
  };
  const { data, isLoading } = useGetRestaurantIngredientsQuery({
    date,
    page: pagination.pageIndex + 1,
    restaurant_id: restaurant.id,
    q: debouncedSearchInput,
  });

  return (
    <>
      <Helmet>
        <title>{t('ingredient.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('ingredient.title')}
          </h1>
        </div>
        <div className="flex justify-between mt-5 mb-12">
          <div>
            <DatePicker
              open={datePickerOpen}
              onOpenChange={setDatePickerOpen}
              date={new Date(date)}
              setDate={(newDate) => {
                if (!newDate) return;
                setDate(format(newDate, 'yyyy-MM-dd'));
                setDatePickerOpen(false);
              }}
            />
          </div>
          <div>
            <Link
              to="/restaurant/ingredients/create"
              className={buttonVariants({ variant: 'default' })}
            >
              {t('ingredient.create')}
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <SearchInput
            className="self-start p-0"
            placeholder={t('common.search-placeholder')}
            searchInput={searchInput}
            handleChange={(event) => {
              handleChange(event);
              setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
            }}
          />
          <DataTable
            columns={memoizedColumns}
            data={data?.data}
            pageCount={data?.total_pages}
            isLoading={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            columnVisibility={{
              edit: hasEditPermission,
            }}
          />
        </div>
      </div>
      <CreateRestaurantIngredientModal />

      {Boolean(
        useMatch({ path: '/restaurant/ingredients/edit/:ingredientId' }),
      ) && <EditRestaurantIngredientModal key={ingredientId} />}

      {Boolean(
        useMatch({
          path: '/restaurant/ingredients/edit-quantity/:ingredientId',
        }),
      ) && <EditRestaurantIngredientQuantityModal key={ingredientId} />}
    </>
  );
};

export default RestaurantIngredients;
