import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantCompanyForm } from '@/components/companies/create-restaurant-company-form';

export const CreateRestaurantCompanyModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => {
    navigate('/restaurant/companies');
  };

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/companies/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company.create')}
      description={t('company.create-details')}
    >
      <CreateRestaurantCompanyForm closeModal={closeModal} />
    </Modal>
  );
};
