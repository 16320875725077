import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetRestaurantEmployeesQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { SearchInput } from '@/components/shared/search-input';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditRestaurantEmployeeModal } from '@/components/employees/edit-restaurant-employee-modal';
import { CreateRestaurantEmployeeModal } from '@/components/employees/create-restaurant-employee-modal';

import { columns } from '@/config/restaurant/employees';
import { useDebouncedSearch } from '@/hooks/use-debounced-search';

const RestaurantEmployees = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const { employeeId } = useParams();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  const { data, isLoading } = useGetRestaurantEmployeesQuery({
    restaurant_id: restaurant.id,
    page: pagination.pageIndex + 1,
    q: debouncedSearchInput,
  });

  return (
    <>
      <Helmet>
        <title>{t('employee.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('employee.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to="/restaurant/employees/create"
              className={buttonVariants({ variant: 'default' })}
            >
              {t('employee.create')}
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            <SearchInput
              className="self-start p-0"
              placeholder={t('common.search-placeholder')}
              searchInput={searchInput}
              handleChange={(event) => {
                handleChange(event);
                setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
              }}
            />
            <DataTable
              columns={memoizedColumns}
              data={data?.data}
              pageCount={data?.total_pages}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              columnVisibility={{
                edit: hasEditPermission,
              }}
            />
          </div>
        </div>
      </div>
      <CreateRestaurantEmployeeModal />
      {Boolean(employeeId) && <EditRestaurantEmployeeModal key={employeeId} />}
    </>
  );
};

export default RestaurantEmployees;
