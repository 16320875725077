import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetNumberOfOrdersPerEmployeeReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { repeat } from '@/utils/helpers';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-location';
import { NumberOfCustomerOrders } from '@/components/report/number-of-customer-orders';

const RestaurantCustomerOrders = () => {
  const { t } = useTranslation();

  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const onUpdateCompanyLocations = (
    newCompanyLocations: ShortRestaurantLocation[],
  ) => {
    setCompanyLocations(newCompanyLocations);
  };

  const restaurant = useAuthUserRestaurant();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const {
    data: companies,
    isSuccess: isSuccess,
    isLoading: isLoading,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data = [],
    isSuccess: isOrdersPerEmployeeSuccess,
    isLoading: isOrdersPerEmpoyeeLoading,
  } = useGetNumberOfOrdersPerEmployeeReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
  });

  const matchedLocations = useMemo(() => {
    if (!isOrdersPerEmployeeSuccess) return [];
    if (!companyLocations.length) return data;
    const selectedCompanyLocationIds = companyLocations.map(({ id }) => id);
    return data?.filter(({ id }) => selectedCompanyLocationIds.includes(id));
  }, [data, isOrdersPerEmployeeSuccess, companyLocations]);

  return (
    <>
      <Helmet>
        <title>{t('reports.restaurant-customers.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.restaurant-customers.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{ from: new Date(from), to: new Date(to) }}
            setDate={([from, to]) => setDateRange({ from, to })}
          />
        </div>
        <div>
          {isSuccess && (
            <SelectCompanyLocations
              companies={companies}
              isLoading={isLoading}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={onUpdateCompanyLocations}
            />
          )}
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton />
        </div>
      </div>
      <div className="flex flex-col">
        {isOrdersPerEmpoyeeLoading && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}
        {isOrdersPerEmployeeSuccess && !isOrdersPerEmpoyeeLoading && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {matchedLocations.map(({ id, name, users }, index) => (
                <div
                  key={id}
                  className="flex flex-col gap-4 print-in-separate-page"
                >
                  <h3 className="text-xl font-medium">{name}</h3>
                  <NumberOfCustomerOrders
                    isTableSyncReference={index === 0}
                    users={users}
                  />
                </div>
              ))}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default RestaurantCustomerOrders;
