import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantMenusQuery,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shared/shadcn-ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/shared/shadcn-ui/table';

import { repeat } from '@/utils/helpers';

interface CopyRestaurantMenuContentProps {
  date: string;
  selectedMenus: number[];
  handleSelectedMenu: (id: number) => void;
}

export const CopyRestaurantMenuContent = ({
  date,
  selectedMenus,
  handleSelectedMenu,
}: CopyRestaurantMenuContentProps) => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const { data, isLoading, isSuccess, isError } = useGetRestaurantMenusQuery({
    restaurant_id: restaurant.id,
    date,
  });

  const isEmpty = isSuccess && Object.keys(data).length === 0;

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="w-24 h-6" />
        <div className="grid grid-cols-2 gap-5 md:grid-cols-3 xl:grid-cols-4">
          {repeat(12).map((_, index) => (
            <Skeleton key={index} className="w-full h-72" />
          ))}
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <Alert
        title={t('common,error')}
        description={t('menu.error-loading')}
        variant="destructive"
        className="mx-auto"
      />
    );
  }

  if (isEmpty) {
    return (
      <div className="text-center text-muted-foreground">
        <p>{t('menu.no-data')}</p>
      </div>
    );
  }

  return (
    <div className="grid flex-1 grid-cols-3 gap-16 p-2 overflow-y-auto">
      {Object.entries(data!).map(([key, value]) => {
        return (
          <div key={key}>
            <h2 className="mb-3 text-lg font-semibold md:text-xl">
              {key}. {t('menu.shift').toLowerCase()}
            </h2>
            <div className="flex flex-col gap-8">
              {value.map((menu) => {
                return (
                  <Card
                    onClick={() => handleSelectedMenu(menu.id)}
                    role="button"
                    key={menu.id}
                    className={`relative flex flex-col w-full rounded-md  ${
                      selectedMenus.includes(menu.id) && 'ring-2 ring-primary'
                    }`}
                  >
                    {selectedMenus.includes(menu.id) && (
                      <Check className="absolute top-2 right-2 text-primary" />
                    )}
                    <CardHeader className="flex-shrink-0 p-3 bg-accent rounded-tl-md rounded-tr-md">
                      <CardTitle
                        title={menu.menu_type.name}
                        className="text-lg truncate text-accent-foreground"
                      >
                        {menu.menu_type.name}
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="flex-1 p-0 bg-card rounded-bl-md rounded-br-md">
                      <Table>
                        <TableBody>
                          {menu.foods.map((food) => (
                            <TableRow key={food.id}>
                              <TableCell>{food.name}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
