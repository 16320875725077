import { useEffect, useState } from 'react';

export const useWindowFocus = () => {
  const [isFocused, setIsFocused] = useState(document.hasFocus());

  useEffect(() => {
    const focusHandler = () => setIsFocused(true);
    const blurHandler = () => setIsFocused(false);

    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);

    return () => {
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, []);

  return isFocused;
};
