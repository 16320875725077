import * as React from 'react';
import { CircleAlert } from 'lucide-react';

import { cn } from '@/utils/helpers';

import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <div className="relative w-full flex-1">
        {error && (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 inline-flex items-center justify-center">
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger>
                  <CircleAlert className="h-4 w-4 text-red-500" />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{error}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            !!error && 'border-red-500 pr-8',
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
