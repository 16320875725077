import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@durma-soft/gros-sdk';

export const AuthErrorMiddleware: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { isError } = useAuthContext();

  if (isError) {
    return <div>{t('common.error')}</div>;
  }

  return <>{children}</>;
};
