import { useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  SHIFT,
  useAuthUserRestaurant,
  useGetSubstituteMealsReportQuery,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { PrintButton } from '@/components/shared/print-button';
import { DataTable } from '@/components/shared/data-table';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/shared/shadcn-ui/dropdown-menu';

import { columns } from '@/config/report/packages';
import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

const SubstituteMealsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const [shiftsDropdownOpen, setShiftsDropdownOpen] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState<RestaurantShift[]>([
    SHIFT.FIRST,
    SHIFT.SECOND,
    SHIFT.THIRD,
  ]);

  const restaurant = useAuthUserRestaurant();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const { data: substituteMealOrders, isSuccess } =
    useGetSubstituteMealsReportQuery({
      restaurant_id: restaurant.id,
      start_date: from,
      end_date: to,
    });

  return (
    <>
      <Helmet>
        <title>{t('reports.substitute-meals.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.substitute-meals.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <DatePickerWithRange
          date={{
            from: new Date(from),
            to: new Date(to),
          }}
          setDate={([from, to]) => {
            setDateRange({ from, to });
          }}
        />
        <DropdownMenu
          open={shiftsDropdownOpen}
          onOpenChange={setShiftsDropdownOpen}
        >
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="gap-2 font-normal">
              {t('common.choose-shifts')}
              <span>
                <ChevronDown size={16} />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {['1', '2', '3'].map((shift) => (
              <DropdownMenuCheckboxItem
                key={shift}
                checked={selectedShifts.includes(shift as RestaurantShift)}
                onSelect={(event) => {
                  event.preventDefault();
                  setSelectedShifts((prev) =>
                    prev.includes(shift as RestaurantShift)
                      ? prev.filter((s) => s !== shift)
                      : [...prev, shift as RestaurantShift],
                  );
                }}
              >
                {shift}. {t('menu.shift')}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="flex-1 justify-end flex">
          <PrintButton />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {isSuccess && !substituteMealOrders.length && (
          <p className="self-center">{t('reports.no-data')}</p>
        )}
        {isSuccess && (
          <TableSizeSyncProvider>
            {substituteMealOrders.map((order, index) => {
              return (
                <div key={order.id} className="flex flex-col gap-4">
                  <h3 className="text-xl font-medium">{order.name}</h3>
                  <DataTable
                    isSyncReference={index === 0}
                    columnVisibility={{
                      shift_1: selectedShifts.includes(SHIFT.FIRST),
                      shift_2: selectedShifts.includes(SHIFT.SECOND),
                      shift_3: selectedShifts.includes(SHIFT.THIRD),
                    }}
                    columns={memoizedColumns}
                    data={order.foods}
                  />
                </div>
              );
            })}
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default SubstituteMealsReport;
