import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeOff } from 'lucide-react';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useMemo, useState } from 'react';
import { RestaurantCompany } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  CompanyEmployee,
  CompanyLocation,
} from '@durma-soft/gros-sdk/dist/types/company';
import {
  ValidationError,
  useEditRestaurantCompanyEmployeeMutation,
  asYouTypePhoneNumber,
  parsePhoneNumber,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
  COMPANY_USER_ROLE,
  RESTAURANT_USER_ROLE,
  Sex,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { SelectRole } from '@/components/shared/select-role';
import { InputAddon } from '@/components/shared/input-addon';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { useChangedObject } from '@/hooks/use-changed-object';

import { isNotEmpty, noop } from '@/utils/helpers';
import { companyRoles } from '@/config/global/roles';
import { EditCompanyEmployeeFormData } from '@/types/employees';
import { RadioGroup, RadioGroupItem } from '../shared/shadcn-ui/radio-group';
import { employeeSex } from '@/config/global';

interface EditRestaurantCompanyEmployeeFormProps {
  company: RestaurantCompany;
  employee: CompanyEmployee;
  locations: CompanyLocation[];
  onClose?: () => unknown;
  onSubmit?: (employee: CompanyEmployee) => unknown;
}

export const EditRestaurantCompanyEmployeeForm = ({
  company,
  locations,
  employee,
  onSubmit = noop,
  onClose = noop,
}: EditRestaurantCompanyEmployeeFormProps) => {
  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required for editing its employees');
  }
  const { t } = useTranslation();

  const restaurant = useAuthUserRestaurant();

  const [countryCode, setCountryCode] = useState<CountryCode>(
    parsePhoneNumber(employee.phone_number)?.country ||
      import.meta.env.VITE_DEFAULT_COUNTRY_CODE ||
      'BA',
  );

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState<EditCompanyEmployeeFormData>({
    email: employee.email,
    first_name: employee.first_name,
    last_name: employee.last_name,
    phone_number:
      parsePhoneNumber(employee.phone_number, countryCode)?.formatNational() ||
      employee.phone_number ||
      '',
    username: employee.username.replace('@' + company.username_suffix, ''),
    primary_location_id: Number(employee.primary_location_id || locationId),
    can_change_location: employee.can_change_location,
    role: `${employee.role}`,
    password: '',
    password_confirmation: '',
    sex: employee.sex,
    max_daily_orders: employee.max_daily_orders,
  });

  const uid = useId();
  const editRestaurantCompanyEmployee =
    useEditRestaurantCompanyEmployeeMutation();

  const [isChanged, changedObj] = useChangedObject(formData, employee, {
    customComparators: {
      username: (a, b) => `${a}@${company.username_suffix}` === b,
      password: (a) => a !== undefined && a === '',
      phone_number: (a, b) => {
        if (!a && !b) return true;
        return (
          parsePhoneNumber(a as string, countryCode)?.format('E.164') === b
        );
      },
    },
    keysToIgnore: ['password_confirmation'],
  });

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.first_name) &&
      isNotEmpty(formData.last_name) &&
      isNotEmpty(formData.username) &&
      doesPasswordsMatch
    );
  }, [formData, doesPasswordsMatch]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged || !isValid) return;
    try {
      const data = await editRestaurantCompanyEmployee.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: Number(companyId),
        employee_id: employee.id,
        ...changedObj,
        username: changedObj.username
          ? `${formData.username}@${company.username_suffix}`
          : undefined,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || '',
        primary_location_id: formData.primary_location_id,
        role: Number(formData.role),
        password: formData.password || undefined,
        password_confirmation: formData.password_confirmation || undefined,
        max_daily_orders: formData.max_daily_orders || undefined,
      });
      onSubmit(data);
      onClose();
      toast.success(t('employee.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('employee.edit-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 overflow-hidden gap-6"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="grid gap-4 py-4">
          {/* Username */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-username'}>{t('common.username')}</Label>
            <div className="flex items-stretch w-full">
              <Input
                id={uid + '-username'}
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                className="flex-1 lowercase border-r-0 rounded-r-none"
                placeholder="marko.markovic"
              />
              <div className="flex items-center justify-center px-3 border border-input rounded-r-md">
                <span className="text-sm text-gray-700 select-none">
                  @{company.username_suffix}
                </span>
              </div>
            </div>
          </div>
          {/* Email */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-email'}>{t('common.email')}</Label>
            <Input
              id={uid + '-email'}
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          {/* First name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-first-name'}>
              {t('common.first-name')}
            </Label>
            <Input
              id={uid + '-first-name'}
              value={formData.first_name}
              onChange={(e) =>
                setFormData({ ...formData, first_name: e.target.value })
              }
            />
          </div>
          {/* Last name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-last-name'}>{t('common.last-name')}</Label>
            <Input
              id={uid + '-last-name'}
              value={formData.last_name}
              onChange={(e) =>
                setFormData({ ...formData, last_name: e.target.value })
              }
            />
          </div>
          {/* Phone number */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-phone-number'}>
              {t('common.phone-number')}
            </Label>
            <div className="flex items-stretch">
              <InputAddon position="left">
                <SelectCountryCallingCode
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value as CountryCode);
                    setFormData({
                      ...formData,
                      phone_number: '',
                    });
                  }}
                />
              </InputAddon>
              <Input
                className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                id={uid + '-phone-number'}
                value={formData.phone_number}
                placeholder={phoneNumberInputPlaceholder[countryCode]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone_number: asYouTypePhoneNumber(
                      e.target.value,
                      countryCode,
                    ),
                  })
                }
              />
            </div>
          </div>
          {/* Role */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-role'}>{t('roles.title')}</Label>
            <SelectRole
              disabled={restaurant.role === RESTAURANT_USER_ROLE.EMPLOYEE}
              roles={companyRoles(t)}
              placeholder={t('roles.choose')}
              value={formData.role}
              onValueChange={(role) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    role: role as `${COMPANY_USER_ROLE}`,
                  };
                });
              }}
            />
          </div>
          {/* Password */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-password'}>{t('common.password')}</Label>
            <div className="flex items-stretch">
              <Input
                error={
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  t('common.passwords-must-match')
                }
                id={uid + '-password'}
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  'border-r'
                } `}
              />
              <InputAddon
                position="right"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 h-4" />
                ) : (
                  <EyeOff className="w-4 h-4" />
                )}
              </InputAddon>
            </div>
          </div>
          {/* Password confirmation */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-password-confirmation'}>
              {t('common.password-confirmation')}
            </Label>
            <div className="flex items-stretch">
              <Input
                error={
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  t('common.passwords-must-match')
                }
                id={uid + '-password-confirmation'}
                type={showPassword ? 'text' : 'password'}
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  'border-r'
                } `}
              />
              <InputAddon
                position="right"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 h-4" />
                ) : (
                  <EyeOff className="w-4 h-4" />
                )}
              </InputAddon>
            </div>
          </div>
          {/* Employee sex */}
          <div className="flex flex-col gap-2">
            <Label>{t('common.sex')}</Label>
            <RadioGroup
              value={formData.sex || 'male'}
              onValueChange={(e) =>
                setFormData((prevData) => {
                  return { ...prevData, sex: e as Sex };
                })
              }
            >
              {employeeSex(t).map(({ value, label }) => (
                <div key={value} className="flex items-center space-x-2">
                  <RadioGroupItem value={value} id={uid + value} />
                  <Label htmlFor={uid + value}>{label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
          {/* Employee company location */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-company-locations'}>
              {t('employee.company-locations')}
            </Label>
            <Select
              value={
                (locations?.find(
                  (location) => formData.primary_location_id === location.id,
                )?.name as string) || ''
              }
              onValueChange={(e) =>
                setFormData({
                  ...formData,
                  primary_location_id: locations?.find(
                    (location) => location.name === (e as string),
                  )?.id as number,
                })
              }
            >
              <SelectTrigger id={uid + '-company-location'}>
                <SelectValue
                  placeholder={t('employee.company-locations-placeholder')}
                />
              </SelectTrigger>
              <SelectContent>
                {locations &&
                  locations?.map((location) => (
                    <SelectItem key={location.id} value={location.name}>
                      {location.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          {/* Can change location */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-change-location'}>
              {t('employee.can-change-location')}
            </Label>
            <Switch
              checked={formData.can_change_location}
              onCheckedChange={(checked) =>
                setFormData((prevData) => {
                  return { ...prevData, can_change_location: Boolean(checked) };
                })
              }
            />
          </div>
          {/* Max daily orders */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-max-daily-orders'}>
              {t('employee.max-daily-orders')}
            </Label>
            <Input
              id={uid + '-max-daily-orders'}
              value={formData.max_daily_orders || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  max_daily_orders: Number(e.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={editRestaurantCompanyEmployee.isPending}
          disabled={!isChanged || !isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
