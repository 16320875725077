import { COMPANY_USER_ROLE, RESTAURANT_USER_ROLE } from '@durma-soft/gros-sdk';

import { TType } from '@/types/general';

export const RESTAURANT_EMPLOYEE_ROLE = (t: TType) => {
  return {
    [RESTAURANT_USER_ROLE.EMPLOYEE]: t('roles.restaurant.employee'),
    [RESTAURANT_USER_ROLE.MANAGER]: t('roles.restaurant.manager'),
    [RESTAURANT_USER_ROLE.OWNER]: t('roles.restaurant.owner'),
  };
};

export const COMPANY_EMPLOYEE_ROLE = (t: TType) => {
  return {
    [COMPANY_USER_ROLE.EMPLOYEE]: t('roles.company.employee'),
    [COMPANY_USER_ROLE.TRACK_EMPLOYEE]: t('roles.company.track'),
    [COMPANY_USER_ROLE.MANAGER]: t('roles.company.manager'),
    [COMPANY_USER_ROLE.OWNER]: t('roles.company.owner'),
  };
};

export const restaurantRoles = (t: TType) => [
  {
    value: String(RESTAURANT_USER_ROLE.EMPLOYEE),
    name: t('roles.restaurant.employee'),
  },
  {
    value: String(RESTAURANT_USER_ROLE.MANAGER),
    name: t('roles.restaurant.manager'),
  },
  {
    value: String(RESTAURANT_USER_ROLE.OWNER),
    name: t('roles.restaurant.owner'),
  },
];

export const companyRoles = (t: TType) => [
  {
    value: String(COMPANY_USER_ROLE.EMPLOYEE),
    name: t('roles.company.employee'),
  },
  {
    value: String(COMPANY_USER_ROLE.TRACK_EMPLOYEE),
    name: t('roles.company.track'),
  },
  {
    value: String(COMPANY_USER_ROLE.MANAGER),
    name: t('roles.company.manager'),
  },
  {
    value: String(COMPANY_USER_ROLE.OWNER),
    name: t('roles.company.owner'),
  },
];
