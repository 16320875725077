import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCreateRestaurantCompanyLocationMutation,
  useGetMenuTypesQuery,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CompanyLocationAddress } from '@/components/companies/company-location-address';
import { CompanyLocationDetails } from '@/components/companies/company-location-details';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/shadcn-ui/tabs';

import { isNotEmpty } from '@/utils/helpers';
import { CompanyLocationFormData } from '@/types/companies';

interface CreateRestaurantCompanyLocationProps {
  closeModal: () => unknown;
}

export const CreateRestaurantCompanyLocationForm = ({
  closeModal,
}: CreateRestaurantCompanyLocationProps) => {
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required for creating location');
  }
  const { t } = useTranslation();

  const [formData, setFormData] = useState<CompanyLocationFormData>({
    name: '',
    shift_1: false,
    shift_2: false,
    shift_3: false,
    location_type: 'direct',
    menu_type_id: null,
    street: '',
    city: '',
    zip: '',
    state: '',
    country: '',
  });

  const restaurant = useAuthUserRestaurant();
  const createRestaurantCompanyLocation =
    useCreateRestaurantCompanyLocationMutation();

  const { data: menuTypes, isLoading } = useGetMenuTypesQuery(restaurant.id);

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name);
  }, [formData.name]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantCompanyLocation.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: Number(companyId),
        name: formData.name,
        shift_1: formData.shift_1,
        shift_2: formData.shift_2,
        shift_3: formData.shift_3,
        location_type: formData.location_type || 'direct',
        menu_type_id: formData.menu_type_id as number,
        street: formData.street,
        city: formData.city,
        zip: formData.zip,
        state: formData.state,
        country: formData.country,
      });
      closeModal();
      toast.success(t('company-locations.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company-locations.create-error-msg'));
    }
  };

  if (isLoading) return <ModalFormSkeleton />;

  return (
    <Tabs defaultValue="details" className="flex flex-col items-start gap-5">
      <form onSubmit={handleSubmit} className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="details" className="flex-1 w-full">
            {t('common.details')}
          </TabsTrigger>
          <TabsTrigger value="address" className="flex-1 w-full">
            {t('common.address')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="details">
          <CompanyLocationDetails
            menuTypes={menuTypes || []}
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <TabsContent value="address" className="w-full">
          <CompanyLocationAddress
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <DialogFooter className="mt-2">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={createRestaurantCompanyLocation.isPending}
          >
            {t('company-locations.create')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
