import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeOff } from 'lucide-react';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useCreateRestaurantEmployeeMutation,
  parsePhoneNumber,
  asYouTypePhoneNumber,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
  RESTAURANT_USER_ROLE,
} from '@durma-soft/gros-sdk';

import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { InputAddon } from '@/components/shared/input-addon';
import { SelectRole } from '@/components/shared/select-role';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';

import { isNotEmpty } from '@/utils/helpers';
import { CreateEmployeeFormData } from '@/types/employees';

import { restaurantRoles } from '@/config/global/roles';

interface CreateRestaurantEmployeeFormProps {
  closeModal: () => unknown;
}

const initialFormData: CreateEmployeeFormData = {
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  role: `${RESTAURANT_USER_ROLE.EMPLOYEE}`,
  password: '',
  password_confirmation: '',
};

export const CreateRestaurantEmployeeForm = ({
  closeModal,
}: CreateRestaurantEmployeeFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [countryCode, setCountryCode] = useState<CountryCode>(
    import.meta.env.VITE_DEFAULT_COUNTRY_CODE || 'BA',
  );

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const createRestaurantEmployee = useCreateRestaurantEmployeeMutation();

  const [showPassword, setShowPassword] = useState(false);

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.first_name) &&
      isNotEmpty(formData.last_name) &&
      isNotEmpty(formData.username) &&
      isNotEmpty(formData.password) &&
      doesPasswordsMatch
    );
  }, [formData, doesPasswordsMatch]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantEmployee.mutateAsync({
        restaurant_id: restaurant.id,
        email: formData.email,
        username:
          `${formData.username}@${restaurant.username_suffix}`.toLowerCase(),
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || null,
        role: Number(formData.role),
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('employee.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('employee.create-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        {/* Username */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-username'}>{t('common.username')}</Label>
          <div className="flex items-stretch w-full">
            <Input
              required
              id={uid + '-username'}
              value={formData.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
              className="flex-1 lowercase border-r-0 rounded-r-none"
              placeholder="marko.markovic"
            />
            <div className="flex items-center justify-center px-3 border border-input rounded-r-md ">
              <span className="text-sm text-gray-700 select-none">
                @{restaurant.username_suffix}
              </span>
            </div>
          </div>
        </div>
        {/* Email */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-email'}>{t('common.email')}</Label>
          <Input
            id={uid + '-email'}
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        {/* Firstname */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-firstName'}>{t('common.first-name')}</Label>
          <Input
            required
            id={uid + '-firstName'}
            value={formData.first_name}
            onChange={(e) =>
              setFormData({ ...formData, first_name: e.target.value })
            }
          />
        </div>
        {/* Lastname */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-lastName'}>{t('common.last-name')}</Label>
          <Input
            required
            id={uid + '-lastName'}
            value={formData.last_name}
            onChange={(e) =>
              setFormData({ ...formData, last_name: e.target.value })
            }
          />
        </div>
        {/* Phone number */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-phone-number'}>
            {t('common.phone-number')}
          </Label>
          <div className="flex items-stretch">
            <InputAddon position="left">
              <SelectCountryCallingCode
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value as CountryCode);
                  setFormData({
                    ...formData,
                    phone_number: '',
                  });
                }}
              />
            </InputAddon>
            <Input
              className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
              id={uid + '-phone-number'}
              value={formData.phone_number}
              placeholder={phoneNumberInputPlaceholder[countryCode]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone_number: asYouTypePhoneNumber(
                    e.target.value,
                    countryCode,
                  ),
                })
              }
            />
          </div>
        </div>
        {/* Role */}
        {restaurant.role === RESTAURANT_USER_ROLE.OWNER && (
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-role'}>{t('roles.title')}</Label>
            <SelectRole
              roles={restaurantRoles(t)}
              placeholder={t('roles.choose')}
              value={formData.role}
              onValueChange={(role) => {
                setFormData((prevData) => {
                  return {
                    ...prevData,
                    role: role as `${RESTAURANT_USER_ROLE}`,
                  };
                });
              }}
            />
          </div>
        )}
        {/* Password */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-password'}>{t('common.password')}</Label>
          <div className="flex items-stretch">
            <Input
              required
              id={`${uid}-password`}
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              error={
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                t('common.passwords-must-match')
              }
              className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                'border-r'
              } `}
            />
            <InputAddon
              position="right"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeIcon className="w-4 h-4" />
              ) : (
                <EyeOff className="w-4 h-4" />
              )}
            </InputAddon>
          </div>
        </div>
        {/* Password confirmation */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-password-confirmation'}>
            {t('common.password-confirmation')}
          </Label>
          <div className="flex items-stretch">
            <Input
              required
              id={`${uid}-password-confirmation`}
              type={showPassword ? 'text' : 'password'}
              error={
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                t('common.passwords-must-match')
              }
              value={formData.password_confirmation}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password_confirmation: e.target.value,
                })
              }
              className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                formData.password_confirmation !== '' &&
                !doesPasswordsMatch &&
                'border-r'
              } `}
            />
            <InputAddon
              position="right"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeIcon className="w-4 h-4" />
              ) : (
                <EyeOff className="w-4 h-4" />
              )}
            </InputAddon>
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantEmployee.isPending}
        >
          {t('employee.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
