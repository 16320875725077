import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import { PrimaryUnit } from '@durma-soft/gros-sdk/dist/types/units';
import { RestaurantIngredient } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserRestaurant,
  useEditIngredientMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { useChangedObject } from '@/hooks/use-changed-object';
import {
  PreferredMeasureUnit,
  RestaurantIngredientUnits,
} from '@/types/ingredients';
import {
  ingredientMeasureUnits,
  ingredientPreferredMeasureUnits,
} from '@/config/global/units';
import { isNotEmpty } from '@/utils/helpers';

interface EditRestaurantIngredientForm {
  ingredient: RestaurantIngredient;
  closeModal: () => unknown;
}

interface FormState {
  name: string;
  unit: PrimaryUnit | string;
  preferred_display_unit: RestaurantIngredientUnits | string;
}

export const EditRestaurantIngredientForm = ({
  ingredient,
  closeModal,
}: EditRestaurantIngredientForm) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormState>({
    name: ingredient.name,
    unit: ingredient.unit,
    preferred_display_unit:
      ingredient.preferred_display_unit || ingredient.unit,
  });

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const editRestaurantIngredient = useEditIngredientMutation();

  const [isChanged, changedObj] = useChangedObject(formData, ingredient);

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name) && formData.unit && isChanged;
  }, [formData, isChanged]);

  const updateUnit = (newValue: PrimaryUnit) => {
    if (newValue === formData.unit) return;
    setFormData((prev) => ({
      ...prev,
      unit: newValue,
      preferred_display_unit: newValue,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged) return;

    try {
      await editRestaurantIngredient.mutateAsync({
        restaurant_id: restaurant.id,
        ingredient_id: ingredient.id,
        ...changedObj,
      });
      closeModal();
      toast.success(t('ingredient.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('ingredient.create-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <div className="flex items-stretch w-full">
            <Input
              required
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-unit'}>
            {t('common.measure-unit.label')}
          </Label>
          <Select
            required
            disabled={!formData.name}
            value={formData.unit}
            onValueChange={(e) => updateUnit(e as PrimaryUnit)}
          >
            <SelectTrigger id={uid + '-unit'}>
              <SelectValue placeholder={t('common.measure-unit.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              {ingredientMeasureUnits(t).map(({ value, name }) => (
                <SelectItem key={value} value={value}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-preferred-unit'}>
            {t('common.measure-unit.shown')}
          </Label>
          <Select
            value={formData.preferred_display_unit}
            onValueChange={(newValue) => {
              if (!newValue) return;
              setFormData({
                ...formData,
                preferred_display_unit: newValue as RestaurantIngredientUnits,
              });
            }}
          >
            <SelectTrigger id={uid + '-preferred-unit'}>
              <SelectValue placeholder={t('common.measure-unit.shown')} />
            </SelectTrigger>
            <SelectContent>
              {formData.unit &&
                (ingredientPreferredMeasureUnits(t) as PreferredMeasureUnit)?.[
                  formData.unit
                ]?.map((preferredUnit) => (
                  <SelectItem
                    key={preferredUnit.value}
                    value={preferredUnit.value}
                  >
                    {preferredUnit.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={editRestaurantIngredient.isPending}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
