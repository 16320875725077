import { useTranslation } from 'react-i18next';
import { FC, ReactNode, useLayoutEffect } from 'react';
import { useAuthContext } from '@durma-soft/gros-sdk';

export const UserLocaleMiddleware: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const { user } = useAuthContext();

  useLayoutEffect(() => {
    if (user && i18n.language !== user.user.locale) {
      i18n.changeLanguage(user.user.locale);
    }
  }, [user, i18n]);

  return <>{children}</>;
};
