import { convertUnits, Unit } from '@durma-soft/gros-sdk';
import { RestaurantFoodWithIngredients } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { TType } from '@/types/general';

export const printFoodWithIngredients = (
  printWindow: Window,
  data: RestaurantFoodWithIngredients,
  t: TType,
) => {
  const html = `
    <html>
      <head>
        <title>${data.name}</title>
        <style>
          ul {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
          }

          h1 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          li {
            font-weight: 500;
            font-size: 1.125rem;
          }
        </style>
      </head>
      <body>
        <h1>${data.name}</h1>
        <ul>
          ${data.ingredients
            .map(
              (ingredient) => `
              <li>
                <span>${ingredient.name}</span>
                <span> - </span>
                <span>${convertUnits({
                  from: ingredient.unit as Unit,
                  to:
                    (ingredient.preferred_display_unit as Unit) ||
                    (ingredient.unit as Unit),
                  quantity: ingredient.quantity,
                })} ${t(
                `common.measure-unit-short.${
                  ingredient.preferred_display_unit || ingredient.unit
                }`,
              )}</span>
              </li>
            `,
            )
            .join('')}
        </ul>
      </body>
    </html>
  `;

  printWindow.document.open();
  printWindow.document.write(html);
  printWindow.print();
  printWindow.close();
};
