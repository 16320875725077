import { useAuthUser } from '@durma-soft/gros-sdk';
import { Navigate } from 'react-router-dom';

const Index = () => {
  const { restaurant } = useAuthUser();

  if (restaurant) {
    return <Navigate to="/restaurant/menus" />;
  }

  // TODO: Check if the user has TRACK role or is a regular employee

  return <Navigate to="/company/locations" />;
};

export default Index;
