import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetInvoicingReportQuery,
} from '@durma-soft/gros-sdk';

import { DataTable } from '@/components/shared/data-table';
import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';

import { repeat } from '@/utils/helpers';
import { columns } from '@/config/report/invoicing';
import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

const InvoicingReport = () => {
  const { t } = useTranslation();

  const restaurant = useAuthUserRestaurant();
  const { from, to, setDateRange } = useDatePickerRangeState();

  const { data, isLoading, isSuccess } = useGetInvoicingReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.invoicing.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.invoicing.title')}
        </h1>
      </div>
      <div className="flex gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{
              from: new Date(from),
              to: new Date(to),
            }}
            setDate={([from, to]) => {
              setDateRange({ from, to });
            }}
          />
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton />
        </div>
      </div>
      <div>
        {isLoading && (
          <div className="flex flex-col gap-10">
            {repeat(3).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}
        {isSuccess && (
          <DataTable columns={columns(t)} data={data} subrowsKey="locations" />
        )}
      </div>
    </>
  );
};

export default InvoicingReport;
