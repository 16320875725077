import { Outlet } from 'react-router-dom';

import { UserCanAccessCompanyMiddleware } from '@/middleware/user-can-access-company-middleware';

const Company = () => {
  return (
    <UserCanAccessCompanyMiddleware>
      <Outlet />
    </UserCanAccessCompanyMiddleware>
  );
};
export default Company;
