import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useGetSingleRestaurantCompanyQuery,
  useGetRestaurantCompanyLocationsQuery,
  useGetSingleRestaurantCompanyEmployeeQuery,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

import { Modal } from '@/components/shared/modal';
import { Alert } from '@/components/shared/alert';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantCompanyEmployeeForm } from '@/components/companies/edit-restaurant-company-employee-form';

export const EditRestaurantCompanyEmployeeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companyId, employeeId, locationId } = useParams();

  if (!companyId || !employeeId) {
    throw new Error('Error - company id / employee id');
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const {
    data: company,
    isSuccess: isSuccessCompany,
    isLoading: isLoadingCompany,
  } = useGetSingleRestaurantCompanyQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
  });

  const {
    data: employee,
    isSuccess: isSuccessEmployee,
    isError,
    isLoading: isLoadingEmployee,
  } = useGetSingleRestaurantCompanyEmployeeQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
    employee_id: Number(employeeId),
  });

  const {
    data: locations,
    isSuccess: isSuccessLocations,
    isLoading: isLoadingLocations,
  } = useGetRestaurantCompanyLocationsQuery({
    restaurant_id: restaurant.id,
    company_id: Number(companyId),
  });

  const isSuccess = isSuccessEmployee && isSuccessLocations && isSuccessCompany;
  const isLoading = isLoadingEmployee || isLoadingLocations || isLoadingCompany;

  const closeModal = () => {
    if (locationId) {
      return navigate(
        `/restaurant/companies/${companyId}/locations/${locationId}/employees`,
      );
    }
    navigate(`/restaurant/companies/${companyId}/employees`);
  };

  const paths = [
    '/restaurant/companies/:companyId/employees/edit/:employeeId',
    '/restaurant/companies/:companyId/locations/:locationId/employees/edit/:employeeId',
  ];

  const isModalOpen = paths.find((path) => matchPath(path, pathname));

  return (
    <Modal
      key={employeeId}
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('employee.edit')}
      description={t('employee.edit-details')}
      className="overflow-hidden flex flex-col"
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantCompanyEmployeeForm
          company={company}
          employee={employee}
          locations={locations}
          onClose={closeModal}
        />
      )}
    </Modal>
  );
};
