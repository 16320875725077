import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  COMPANY_USER_ROLE,
  useAuthUserCompany,
  useGetCompanyEmployeesQuery,
} from '@durma-soft/gros-sdk';

import { DataTable } from '@/components/shared/data-table';
import { SearchInput } from '@/components/shared/search-input';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditCompanyEmployeeModal } from '@/components/company/edit-company-employee-modal';
import { CreateCompanyEmployeeModal } from '@/components/company/create-company-employee-modal';

import { useDebouncedSearch } from '@/hooks/use-debounced-search';

import { columns } from '@/config/company/employees';

const CompanyEmployees = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role === COMPANY_USER_ROLE.MANAGER ||
    company.role === COMPANY_USER_ROLE.OWNER;

  const { employeeId } = useParams();

  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  const { data, isLoading } = useGetCompanyEmployeesQuery({
    company_id: company.id,
    page: pagination.pageIndex + 1,
    q: debouncedSearchInput,
  });

  return (
    <>
      <Helmet>
        <title>{t('employee.title')}</title>
      </Helmet>

      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('employee.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/company/employees/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('employee.create')}
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            <SearchInput
              className="self-start p-0"
              placeholder={t('common.search-placeholder')}
              searchInput={searchInput}
              handleChange={(event) => {
                handleChange(event);
                setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
              }}
            />
            <DataTable
              columns={memoizedColumns}
              data={data?.data}
              pageCount={data?.total_pages}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              columnVisibility={{
                edit: hasEditPermission,
              }}
            />
          </div>
        </div>
      </div>
      <CreateCompanyEmployeeModal />
      {Boolean(employeeId) && <EditCompanyEmployeeModal key={employeeId} />}
    </>
  );
};

export default CompanyEmployees;
