import { ChangeEvent } from 'react';
import { CountryCode } from 'libphonenumber-js';

import { countryCodes } from '@/config/global/country-code';

export const SelectCountryCallingCode = ({
  value,
  onChange,
}: {
  value: CountryCode;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <select value={value} onChange={onChange} className="focus:outline-none">
      {countryCodes.map(({ code, content, country }) => (
        <option key={code} value={country}>
          {content}
        </option>
      ))}
    </select>
  );
};
