import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import { CompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import {
  ValidationError,
  useAuthUserRestaurant,
  useEditRestaurantCompanyLocationMutation,
  useGetMenuTypesQuery,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { CompanyLocationDetails } from '@/components/companies/company-location-details';
import { CompanyLocationAddress } from '@/components/companies/company-location-address';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '@/components/shared/shadcn-ui/tabs';

import { isNotEmpty } from '@/utils/helpers';
import { CompanyLocationFormData } from '@/types/companies';
import { useChangedObject } from '@/hooks/use-changed-object';

interface EditRestaurantCompanyLocationProps {
  location: CompanyLocation;
  closeModal: () => unknown;
}

export const EditRestaurantCompanyLocationForm = ({
  location,
  closeModal,
}: EditRestaurantCompanyLocationProps) => {
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required for creating location');
  }
  const { t } = useTranslation();

  const [formData, setFormData] = useState<CompanyLocationFormData>({
    name: location.name,
    shift_1: location.shift_1,
    shift_2: location.shift_2,
    shift_3: location.shift_3,
    location_type: location.location_type,
    menu_type_id: location.menu_type ? location.menu_type.id : null,
    street: location.street || undefined,
    city: location.city || undefined,
    zip: location.zip || undefined,
    state: location.state || undefined,
    country: location.country || undefined,
  });

  const restaurant = useAuthUserRestaurant();
  const editRestaurantCompanyLocation =
    useEditRestaurantCompanyLocationMutation();

  const [isChanged, changedObj] = useChangedObject(formData, location, {
    customComparators: {
      menu_type_id: (a) => {
        return a === location.menu_type?.id;
      },
    },
  });

  const { data: menuTypes, isLoading } = useGetMenuTypesQuery(restaurant.id);

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name) && isChanged;
  }, [formData.name, isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await editRestaurantCompanyLocation.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: Number(companyId),
        location_id: Number(location.id),
        ...changedObj,
        menu_type_id: Number(formData.menu_type_id),
      });
      closeModal();
      toast.success(t('company-locations.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company-locations.edit-error-msg'));
    }
  };

  if (isLoading) return <ModalFormSkeleton />;

  return (
    <Tabs defaultValue="details" className="flex flex-col items-start gap-5">
      <form onSubmit={handleSubmit} className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="details" className="flex-1 w-full">
            {t('common.details')}
          </TabsTrigger>
          <TabsTrigger value="address" className="flex-1 w-full">
            {t('common.address')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="details">
          <CompanyLocationDetails
            menuTypes={menuTypes || []}
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <TabsContent value="address" className="w-full">
          <CompanyLocationAddress
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <DialogFooter className="mt-2">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={editRestaurantCompanyLocation.isPending}
          >
            {t('common.save-edit')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
