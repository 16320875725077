import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Checkbox } from '@/components/shared/shadcn-ui/checkbox';

import { shifts as localizedShifts } from '@/config/restaurant/menus';
import { EditRestaurantMenuFormData } from '@/types/menus';

interface MenuDetailsProps {
  formData: EditRestaurantMenuFormData;
}

export const EditMenuDetails = ({ formData }: MenuDetailsProps) => {
  const uid = useId();
  const { t } = useTranslation();

  return (
    <div className="grid">
      {/* Menu type */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-menu-type'}>{t('menu.menu-type')}</Label>
        <div className="flex items-center gap-3 mb-2">
          <Checkbox
            id={formData.menu_type.name}
            checked={Boolean(formData.menu_type.name)}
            disabled
          />
          <label
            htmlFor={formData.menu_type.name}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {formData.menu_type.name}
          </label>
        </div>
      </div>

      {/* Shift/s */}
      <div className="flex flex-col gap-2">
        <Label htmlFor={uid + '-shift'}>{t('menu.shift')}</Label>
        <div className="flex items-center gap-3 mb-2">
          <Checkbox
            disabled
            id={String(formData.shift)}
            checked={Boolean(formData.shift)}
          />
          <label
            htmlFor={String(formData.shift)}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {
              localizedShifts(t)?.find(
                (shift) => shift.id === Number(formData.shift),
              )?.label
            }
          </label>
        </div>
      </div>
    </div>
  );
};
