import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/shared/shadcn-ui/select';

import { noop } from '@/utils/helpers';

interface TimePickerProps {
  time: { hour: string; minute: string };
  handleTimeChange: (hour: string, minute: string) => unknown;
}

export const TimePicker = ({
  time,
  handleTimeChange = noop,
}: TimePickerProps) => {
  const hoursOptions = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, '0'),
  );
  const minutesOptions = ['00', '15', '30', '45'];

  const timeOptions = hoursOptions.flatMap((hour) =>
    minutesOptions.map((minute) => `${hour}:${minute}`),
  );

  return (
    <Select
      value={`${time.hour}:${time.minute}`}
      onValueChange={(value) => {
        const [hour, minute] = value.split(':');
        handleTimeChange(hour, minute);
      }}
    >
      <SelectTrigger className="select-trigger">{`${time.hour}:${time.minute}`}</SelectTrigger>
      <SelectContent className="select-content">
        {timeOptions.map((time) => (
          <SelectItem key={time} value={time} className="select-item">
            {time}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
