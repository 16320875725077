import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import { RestaurantFoodWithIngredients } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useAuthUserRestaurant,
  useEditFoodMutation,
} from '@durma-soft/gros-sdk';

import { FoodDetails } from '@/components/foods/food-details';
import { Button } from '@/components/shared/shadcn-ui/button';
import { IngredientsTab } from '@/components/foods/ingredients-tab';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/shadcn-ui/tabs';

import { useChangedObject } from '@/hooks/use-changed-object';

import { isNotEmpty } from '@/utils/helpers';
import { RestaurantFoodFormData } from '@/types/foods';

interface EditRestaurantFoodForm {
  food: RestaurantFoodWithIngredients;
  closeModal: () => unknown;
}

export const EditRestaurantFoodForm = ({
  food,
  closeModal,
}: EditRestaurantFoodForm) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<RestaurantFoodFormData>({
    name: food.name,
    fasting: food.fasting,
    halal: food.halal,
    vegan: food.vegan,
    vegetarian: food.vegetarian,
    is_substitute: food.is_substitute,
    picture: food.picture,
    ingredients: food.ingredients,
  });

  const restaurant = useAuthUserRestaurant();
  const editRestaurantFood = useEditFoodMutation();

  const [isChanged, changedObj] = useChangedObject(formData, food);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.name) &&
      formData.ingredients.every((ingredient) => {
        return (
          Boolean(ingredient.quantity) &&
          Number.isFinite(+ingredient.quantity) &&
          ingredient.quantity > 0
        );
      }) &&
      isChanged
    );
  }, [formData, isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged) return;

    try {
      await editRestaurantFood.mutateAsync({
        food_id: food.id,
        restaurant_id: restaurant.id,
        ...changedObj,
        picture:
          typeof formData.picture === 'string' ? undefined : formData.picture,
        ingredients: formData.ingredients.map(({ id, quantity }) => ({
          ingredient_id: id,
          quantity,
        })),
      });
      closeModal();
      toast.success(t('food.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        toast.error(error.message);
      }
      toast.error(t('food.edit-error-msg'));
    }
  };

  return (
    <Tabs
      defaultValue="details"
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 flex-1 overflow-hidden"
      >
        <div className="flex-1 overflow-y-auto pr-3">
          <TabsList className="w-full">
            <TabsTrigger value="details" className="flex-1 w-full">
              {t('common.details')}
            </TabsTrigger>
            <TabsTrigger value="ingredients" className="flex-1 w-full">
              {t('food.ingredients')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details">
            <FoodDetails formData={formData} setFormData={setFormData} />
          </TabsContent>
          <TabsContent value="ingredients" className="w-full">
            <IngredientsTab
              ingredients={formData.ingredients}
              setFormData={setFormData}
            />
          </TabsContent>
        </div>
        <DialogFooter className="self-end">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={editRestaurantFood.isPending}
          >
            {t('common.save-edit')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
