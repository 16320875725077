import { FC, ReactNode } from 'react';
import { useAuthContext } from '@durma-soft/gros-sdk';

import { Spinner } from '@/components/shared/spinner';

export const AuthLoadingMiddleware: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { isLoading } = useAuthContext();

  if (isLoading) {
    return (
      <div className="grid h-full place-items-center">
        <Spinner />
      </div>
    );
  }

  return <>{children}</>;
};
