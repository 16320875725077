import { ColumnDef } from '@tanstack/react-table';
import { FoodReportRow } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

const sumShifts = (row: FoodReportRow) => {
  const { shift_1 = 0, shift_2 = 0, shift_3 = 0 } = row;
  return shift_1 + shift_2 + shift_3;
};

export const columns = (t: TType): ColumnDef<FoodReportRow>[] => [
  {
    header: t('common.order-number'),
    cell: ({ row: { index } }) => <>{Number(index + 1)}</>,
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'shift_1',
    header: t('shifts.first'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_1, 0);
    },
  },
  {
    accessorKey: 'shift_2',
    header: t('shifts.second'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_2, 0);
    },
  },
  {
    accessorKey: 'shift_3',
    header: t('shifts.third'),
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + row.original.shift_3, 0);
    },
  },
  {
    accessorKey: 'foods_total',
    header: t('common.total'),
    cell: (cellData) => {
      const totalCount = sumShifts(cellData.row.original);
      const formattedCount = Math.round(totalCount * 100) / 100;
      return formattedCount;
    },
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      if (rows.length === 0) return 0;
      return rows.reduce((total, row) => total + sumShifts(row.original), 0);
    },
  },
];
