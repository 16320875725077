import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantMenuTypeForm } from '@/components/menu-types/create-restaurant-menu-type-form';
import { useTranslation } from 'react-i18next';

export const CreateRestaurantMenuTypeModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate('/restaurant/menu-types');

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/menu-types/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('menu-type.create')}
      description={t('menu-type.create-details')}
    >
      <CreateRestaurantMenuTypeForm closeModal={closeModal} />
    </Modal>
  );
};
