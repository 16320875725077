import { format } from 'date-fns';
import { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDatePickerRangeState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const from = searchParams.get('from') || format(Date.now(), 'yyyy-MM-dd');
  const to = searchParams.get('to') || format(Date.now(), 'yyyy-MM-dd');

  useLayoutEffect(() => {
    if (!searchParams.has('from') || !searchParams.has('to')) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        from: format(Date.now(), 'yyyy-MM-dd'),
        to: format(Date.now(), 'yyyy-MM-dd'),
      });
    }
  }, [searchParams, setSearchParams]);

  const setDateRange = (newDateRange: { from: string; to: string }) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      from: newDateRange.from,
      to: newDateRange.to,
    });
  };

  return {
    from,
    to,
    setDateRange,
  };
};
