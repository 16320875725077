import { SidebarNav } from '@/layout/sidebar/sidebar-nav';
import { SidebarHeader } from '@/layout/sidebar/sidebar-header';

const Sidebar = () => {
  return (
    <div className="hidden border-r bg-muted/40 md:w-[200px] lg:w-[260px] xl:w-[320px] md:flex flex-col gap-2">
      <SidebarHeader />
      <SidebarNav />
    </div>
  );
};

export default Sidebar;
