import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

import debounce from '@/utils/debounce';

interface TableSizeSyncContextType {
  setTableHeaderReference: (
    columnName: string,
    element: HTMLTableCellElement,
  ) => void;
  getTableHeaderWidth: (columnName: string) => number | null;
}

const TableSizeSyncContext = createContext<TableSizeSyncContextType>({
  setTableHeaderReference: () => {},
  getTableHeaderWidth: () => 0,
});

const TableSizeSyncProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [tableHeaderReferenceElements, setTableHeaderReferenceElements] =
    useState<Record<string, HTMLTableCellElement>>({});

  const [tableHeaderWidths, setTableHeaderWidths] = useState<
    Record<string, number>
  >({});

  const setTableHeaderReference = (
    columnName: string,
    element: HTMLTableCellElement,
  ) => {
    if (tableHeaderReferenceElements[columnName] === element) {
      return;
    }
    setTableHeaderReferenceElements((prev) => ({
      ...prev,
      [columnName]: element,
    }));
  };

  const getTableHeaderWidth = (columnName: string) => {
    return tableHeaderWidths[columnName] || null;
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      const newTableHeaderWidths = Object.entries(
        tableHeaderReferenceElements,
      ).reduce((acc, [columnName, element]) => {
        acc[columnName] = Math.round(element.offsetWidth);
        return acc;
      }, {} as Record<string, number>);

      setTableHeaderWidths(newTableHeaderWidths);
    };

    handleResize();

    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [tableHeaderReferenceElements]);

  return (
    <TableSizeSyncContext.Provider
      value={{ setTableHeaderReference, getTableHeaderWidth }}
    >
      {children}
    </TableSizeSyncContext.Provider>
  );
};

export default TableSizeSyncProvider;

export const useTableSizeSync = () => useContext(TableSizeSyncContext);
