import { useEffect, useRef } from 'react';
import { Balancer } from 'react-wrap-balancer';
import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserCompany,
  useGetTrackPickedUpOrdersQuery,
} from '@durma-soft/gros-sdk';

import { repeat } from '@/utils/helpers';

import { Alert } from '@/components/shared/alert';
import { Card } from '@/components/shared/shadcn-ui/card';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';

interface PickedUpOrdersProps {
  shift: RestaurantShift;
}

export const PickedUpOrders = ({ shift }: PickedUpOrdersProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { id: company_id } = useAuthUserCompany();
  const { t } = useTranslation();

  const { data, isLoading, isSuccess, isError } =
    useGetTrackPickedUpOrdersQuery({
      company_id,
      shift,
    });

  useEffect(() => {
    if (isSuccess) {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [data, isSuccess]);

  return (
    <div
      ref={containerRef}
      className="fixed right-0 top-14 lg:top-15 bottom-0 w-80 overflow-y-auto flex flex-col gap-6 bg-muted/40 border-l p-5"
    >
      {isLoading &&
        repeat(10).map((_, i) => <Skeleton className="h-32" key={i} />)}
      {isError && (
        <div>
          <Alert
            variant="destructive"
            title={t('picked-up-orders.previous-orders-error')}
            description={t('picked-up-orders.previous-orders-error-msg')}
          />
        </div>
      )}
      {isSuccess && data.length === 0 && (
        <p className="text-center text-muted-foreground text-sm">
          <Balancer>{t('track.picked-up-orders.placeholder')}</Balancer>
        </p>
      )}
      {isSuccess &&
        data.length > 0 &&
        data.map(({ user, foods }) => (
          <Card className="p-4" key={user.id}>
            <p className="text-sm font-semibold mb-3">
              {user.first_name} {user.last_name}
            </p>
            <ul className="space-y-2 text-sm">
              {foods.map((food) => (
                <li key={food.id}>
                  <span className="font-medium">{food.quantity}x</span>{' '}
                  {food.name}
                </li>
              ))}
            </ul>
          </Card>
        ))}
    </div>
  );
};
