import { GripIcon, Trash2 } from 'lucide-react';
import { useSortable } from '@dnd-kit/sortable';
import { ColumnDef } from '@tanstack/react-table';
import { ShortRestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { type UniqueIdentifier } from '@dnd-kit/core';

import { Button } from '@/components/shared/shadcn-ui/button';

import { TType } from '@/types/general';

const RowDragHandleCell = ({ rowId }: { rowId: UniqueIdentifier }) => {
  const { attributes, listeners } = useSortable({ id: `${rowId}` });

  return (
    <button {...attributes} {...listeners}>
      <GripIcon />
    </button>
  );
};

export const columns = (
  t: TType,
  onDeleteFood: (id: number) => void,
): ColumnDef<ShortRestaurantFood>[] => [
  {
    id: 'drag-handle',
    header: '',
    cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
    size: 60,
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'edit',
    header: '',
    cell: (cellData) => {
      return (
        <div className="flex items-center justify-end w-full">
          <Button
            variant="ghost"
            onClick={() => onDeleteFood(cellData.row.original.id)}
          >
            <Trash2 />
          </Button>
        </div>
      );
    },
  },
];
