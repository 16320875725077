import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTable } from '@/components/shared/data-table';

import { columns } from '@/config/report/number-of-customer-orders';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

interface NumberOfCustomerOrdersProps {
  users: NumberOfOrdersPerEmployeeReportUser[];
  isTableSyncReference?: boolean;
}

export const NumberOfCustomerOrders = ({
  users,
  isTableSyncReference,
}: NumberOfCustomerOrdersProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(() => columns(t), [t]);

  return (
    <DataTable
      isSyncReference={isTableSyncReference}
      data={users}
      columns={memoizedColumns}
    />
  );
};
