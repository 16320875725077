import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useGetAllFoodsQuery } from '@durma-soft/gros-sdk';
import { ShortRestaurantFood } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';

interface FoodComboboxProps {
  restaurantId: number;
  selectedFoods: ShortRestaurantFood[];
  onUpdateFoods: (foods: ShortRestaurantFood[]) => unknown;
}

export const FoodsCombobox = ({
  restaurantId,
  selectedFoods,
  onUpdateFoods,
}: FoodComboboxProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data: foods, isLoading } = useGetAllFoodsQuery(restaurantId);

  if (isLoading) return <Spinner />;

  const toggleFood = (food: ShortRestaurantFood) => {
    if (selectedFoods.some((i) => i.id === food.id)) {
      onUpdateFoods(selectedFoods.filter((i) => i.id !== food.id));
    } else {
      onUpdateFoods([...selectedFoods, { ...food }]);
    }
  };

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedFoods.length > 0
            ? `${selectedFoods.length} ${t('common.chosen')}`
            : t('menu.choose-foods')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={t('common.search-placeholder')} />
          <CommandEmpty>{t('menu.food-not-found')}</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {foods &&
                foods.map((food) => (
                  <CommandItem
                    onSelect={() => {
                      toggleFood(food);
                    }}
                    key={food.id}
                    value={String(food.name) + String(food.id)}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedFoods.some((i) => i.id === food.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {food.name}
                  </CommandItem>
                ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
