import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/shared/alert';

export const UnknownTrackError = () => {
  const { t } = useTranslation();
  return (
    <Alert
      variant="destructive"
      title={t('track.errors.unknown.heading')}
      description={t('track.errors.unknown.description')}
    />
  );
};
