import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import { RestaurantMenuType } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  ValidationError,
  useAuthUserRestaurant,
  useEditMenuTypeMutation,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';

import { noop } from '@/utils/helpers';
import { useChangedObject } from '@/hooks/use-changed-object';
import { RestaurantMenuTypeFormData } from '@/types/menu-types';

interface EditRestaurantMenuTypeFormProps {
  menuType: RestaurantMenuType;
  onClose?: () => unknown;
}

export const EditRestaurantMenuTypeForm = ({
  menuType,
  onClose = noop,
}: EditRestaurantMenuTypeFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<RestaurantMenuTypeFormData>({
    name: menuType.name,
  });

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const editRestaurantMenuType = useEditMenuTypeMutation();

  const [isChanged, changedObj] = useChangedObject(formData, menuType);

  const isValid = useMemo(() => {
    return formData.name.length > 0;
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged || !isValid) return;
    try {
      await editRestaurantMenuType.mutateAsync({
        restaurant_id: restaurant.id,
        menu_type_id: menuType.id,
        ...changedObj,
      });
      onClose();
      toast.success(t('menu-type.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('menu-type.edit-error.msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={editRestaurantMenuType.isPending}
          disabled={!isChanged || !isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
