import { useMemo } from 'react';
import deepEqual from 'deep-equal';

interface OptionalUseChangedObjectProps<T extends object, N extends object> {
  keysToCompare?: string[];
  keysToIgnore?: string[];
  customComparators?: Partial<
    Record<keyof T, (a: T[keyof T], b: N[keyof N]) => boolean>
  >;
}

export const useChangedObject = <T extends object, N extends object>(
  firstObject: T,
  secondObject: N,
  {
    keysToCompare: keysToCompareProp,
    keysToIgnore: keysToIgnoreProp,
    customComparators = {},
  }: OptionalUseChangedObjectProps<T, N> = {},
) => {
  const [isChanged, changedObj] = useMemo(() => {
    const keysToCompare = keysToCompareProp || Object.keys(firstObject);
    const keysToIgnore = keysToIgnoreProp || [];

    const finalKeysToCompare = keysToCompare.filter(
      (key) => !keysToIgnore.includes(key),
    );

    let isChanged = false;
    const changed: Partial<T> = {};

    finalKeysToCompare.forEach((key) => {
      const customComparator = customComparators[key as keyof T];
      const firstValue = firstObject[key as keyof T];
      const secondValue = secondObject[key as keyof N];

      const comparatorFn = customComparator || deepEqual;

      if (!comparatorFn(firstValue, secondValue)) {
        changed[key as keyof T] = firstValue as T[keyof T];
        isChanged = true;
      }
    });

    return [isChanged, changed] as const;
  }, [
    firstObject,
    secondObject,
    keysToCompareProp,
    keysToIgnoreProp,
    customComparators,
  ]);

  return [isChanged, changedObj] as const;
};
