import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeOff } from 'lucide-react';
import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useId, useMemo, useState } from 'react';
import { CompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import { RestaurantCompany } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  parsePhoneNumber,
  asYouTypePhoneNumber,
  Sex,
  ValidationError,
  useCreateRestaurantCompanyEmployeeMutation,
  phoneNumberInputPlaceholder,
  useAuthUserRestaurant,
  RESTAURANT_USER_ROLE,
  COMPANY_USER_ROLE,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';
import { SelectRole } from '@/components/shared/select-role';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCountryCallingCode } from '@/components/shared/select-country-code';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';

import { employeeSex } from '@/config/global';
import { companyRoles } from '@/config/global/roles';

import { isNotEmpty } from '@/utils/helpers';
import { CreateCompanyEmployeeFormData } from '@/types/employees';

interface CreateRestaurantCompanyEmployeeFormProps {
  company: RestaurantCompany;
  locations: CompanyLocation[];
  closeModal: () => unknown;
}

const initialFormData: CreateCompanyEmployeeFormData = {
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  role: `${COMPANY_USER_ROLE.EMPLOYEE}`,
  password: '',
  password_confirmation: '',
  primary_location_id: null,
  can_change_location: false,
  sex: 'male',
  max_daily_orders: 1,
};

export const CreateRestaurantCompanyEmployeeForm = ({
  company,
  locations,
  closeModal,
}: CreateRestaurantCompanyEmployeeFormProps) => {
  const { t } = useTranslation();
  const { companyId, locationId } = useParams();

  if (!companyId) {
    throw new Error('Company ID is required getting employees');
  }

  const [formData, setFormData] = useState({
    ...initialFormData,
    primary_location_id: Number(locationId) || null,
  });

  const [countryCode, setCountryCode] = useState<CountryCode>(
    import.meta.env.VITE_DEFAULT_COUNTRY_CODE || 'BA',
  );

  const [showPassword, setShowPassword] = useState(false);

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const createRestaurantCompanyEmployee =
    useCreateRestaurantCompanyEmployeeMutation();

  const doesPasswordsMatch = useMemo(() => {
    return formData.password === formData.password_confirmation;
  }, [formData.password, formData.password_confirmation]);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.first_name) &&
      isNotEmpty(formData.last_name) &&
      isNotEmpty(formData.username) &&
      isNotEmpty(formData.password) &&
      doesPasswordsMatch
    );
  }, [formData, doesPasswordsMatch]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantCompanyEmployee.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: Number(companyId),
        email: formData.email || null,
        username:
          `${formData.username}@${company.username_suffix}`.toLowerCase(),
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number:
          parsePhoneNumber(formData.phone_number, countryCode)?.format(
            'E.164',
          ) || null,
        role: Number(formData.role),
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        primary_location_id: formData.primary_location_id as number,
        can_change_location: formData.can_change_location,
        sex: formData.sex || 'male',
        max_daily_orders: formData.max_daily_orders,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('employee.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('employee.create-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 overflow-hidden gap-6"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="grid gap-4 py-4">
          {/* Username */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-username'}>{t('common.username')}</Label>
            <div className="flex items-stretch w-full">
              <Input
                required
                id={uid + '-username'}
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                className="flex-1 lowercase border-r-0 rounded-r-none"
                placeholder="marko.markovic"
              />
              <div className="flex items-center justify-center px-3 border border-input rounded-r-md ">
                <span className="text-sm text-gray-700 select-none">
                  @{company.username_suffix}
                </span>
              </div>
            </div>
          </div>
          {/* Email */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-email'}>{t('common.email')}</Label>
            <Input
              id={uid + '-email'}
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          {/* First name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-first-name'}>
              {t('common.first-name')}
            </Label>
            <Input
              required
              id={uid + '-first-name'}
              value={formData.first_name}
              onChange={(e) =>
                setFormData({ ...formData, first_name: e.target.value })
              }
            />
          </div>
          {/* Last name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-last-name'}>{t('common.last-name')}</Label>
            <Input
              required
              id={uid + '-last-name'}
              value={formData.last_name}
              onChange={(e) =>
                setFormData({ ...formData, last_name: e.target.value })
              }
            />
          </div>
          {/* Phone number */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-phone-number'}>
              {t('common.phone-number')}
            </Label>
            <div className="flex items-stretch">
              <InputAddon position="left">
                <SelectCountryCallingCode
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value as CountryCode);
                    setFormData({
                      ...formData,
                      phone_number: '',
                    });
                  }}
                />
              </InputAddon>
              <Input
                className="flex-1 border-l-0 rounded-tl-none rounded-bl-none"
                id={uid + '-phone-number'}
                placeholder={phoneNumberInputPlaceholder[countryCode]}
                value={formData.phone_number}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone_number: asYouTypePhoneNumber(
                      e.target.value,
                      countryCode,
                    ),
                  })
                }
              />
            </div>
          </div>
          {/* Role */}
          {restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE && (
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-role'}>{t('roles.title')}</Label>
              <SelectRole
                roles={companyRoles(t)}
                placeholder={t('roles.choose')}
                value={formData.role}
                onValueChange={(role) => {
                  setFormData((prevData) => {
                    return {
                      ...prevData,
                      role: role as `${COMPANY_USER_ROLE}`,
                    };
                  });
                }}
              />
            </div>
          )}
          {/* Password */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-password'}>{t('common.password')}</Label>
            <div className="flex items-stretch">
              <Input
                required
                id={`${uid}-password`}
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                error={
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  t('common.passwords-must-match')
                }
                className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  'border-r'
                } `}
              />
              <InputAddon
                position="right"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 h-4" />
                ) : (
                  <EyeOff className="w-4 h-4" />
                )}
              </InputAddon>
            </div>
          </div>
          {/* Password confirmation */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-password-confirmation'}>
              {t('common.password-confirmation')}
            </Label>
            <div className="flex items-stretch">
              <Input
                required
                id={`${uid}-password-confirmation`}
                type={showPassword ? 'text' : 'password'}
                error={
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  t('common.passwords-must-match')
                }
                value={formData.password_confirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password_confirmation: e.target.value,
                  })
                }
                className={`flex-1 border-r-0 rounded-tr-none rounded-br-none ${
                  formData.password_confirmation !== '' &&
                  !doesPasswordsMatch &&
                  'border-r'
                } `}
              />
              <InputAddon
                position="right"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 h-4" />
                ) : (
                  <EyeOff className="w-4 h-4" />
                )}
              </InputAddon>
            </div>
          </div>
          {/* Employee sex */}
          <div className="flex flex-col gap-2">
            <Label>{t('common.sex')}</Label>
            <RadioGroup
              value={formData.sex || 'male'}
              onValueChange={(e) =>
                setFormData((prevData) => {
                  return { ...prevData, sex: e as Sex };
                })
              }
            >
              {employeeSex(t)?.map(({ value, label }) => (
                <div key={value} className="flex items-center space-x-2">
                  <RadioGroupItem value={value} id={uid + value} />
                  <Label htmlFor={uid + value}>{label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
          {/* Employee company location */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-company-locations'}>
              {t('employee.company-locations')}
            </Label>
            <Select
              value={
                locations.find(
                  (location) => formData.primary_location_id === location.id,
                )?.name as string
              }
              onValueChange={(e) => {
                setFormData({
                  ...formData,
                  primary_location_id: locations.find(
                    (location) => location.name === (e as string),
                  )?.id as number,
                });
              }}
            >
              <SelectTrigger id={uid + '-company-location'}>
                <SelectValue
                  placeholder={t('employee.company-locations-placeholder')}
                />
              </SelectTrigger>
              <SelectContent>
                {locations &&
                  locations?.map((location) => (
                    <SelectItem key={location.id} value={location.name}>
                      {location.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          {/* Can change location */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-change-location'}>
              {t('employee.can-change-location')}
            </Label>
            <Switch
              checked={formData.can_change_location}
              onCheckedChange={(checked) =>
                setFormData((prevData) => {
                  return { ...prevData, can_change_location: Boolean(checked) };
                })
              }
            />
          </div>
          {/* Max daily orders */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-max-daily-orders'}>
              {t('employee.max-daily-orders')}
            </Label>
            <Input
              required
              id={uid + '-max-daily-orders'}
              value={formData.max_daily_orders || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  max_daily_orders: Number(e.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantCompanyEmployee.isPending}
        >
          {t('employee.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
