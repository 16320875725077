import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCreateMenuTypeMutation,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';

import { RestaurantMenuTypeFormData } from '@/types/menu-types';

interface CreateRestaurantMenuTypeFormProps {
  closeModal: () => unknown;
}

const initialFormData: RestaurantMenuTypeFormData = {
  name: '',
};

export const CreateRestaurantMenuTypeForm = ({
  closeModal,
}: CreateRestaurantMenuTypeFormProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialFormData);

  const uid = useId();

  const restaurant = useAuthUserRestaurant();
  const createRestaurantMenuType = useCreateMenuTypeMutation();

  const isValid = useMemo(() => {
    return formData.name.length > 0;
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantMenuType.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('menu-type.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('menu-type.create-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantMenuType.isPending}
        >
          {t('menu-type.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
